import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import chevronLeft from '../../assets/chevronLeft.svg';
import CtaButton from '../../components/CtaButton';
import { Link, useNavigate } from 'react-router-dom';
import PreOnboarding from '../../assets/PreOnboarding.svg';
import axios from 'axios';
import calendarIcon from '../../assets/calendarIcon.svg';
import Loader from '../../utils/Loader';

// HeightInput Component
const HeightInput = ({
  value,
  unit,
  feet,
  inches,
  onUnitChange,
  onFeetChange,
  onInchesChange,
  onChange,
  hasError,
}) => {
  const [error, setError] = useState('');

  const getInputClassName = (hasFieldError) => {
    return `text-md-regular ${
      hasFieldError
        ? 'shadow-user_details_input_error border-[#F97066] border-[1px]'
        : 'hover:shadow-user_details_input hover:border-secondary_500 hover:border-[1px]'
    } bg-[#ffffff99] rounded-[12px] border-[#00000012] border-[1px] text-gray_800 placeholder-light_bg_primary_0.35 w-full px-3 py-3 pl-[10px] focus:outline-none`;
  };

  // When unit changes from cm to ft/in
  useEffect(() => {
    if (unit === 'ft' && value) {
      const totalInches = value / 2.54;
      const ft = Math.floor(totalInches / 12);
      const inc = Math.round(totalInches % 12);
      onFeetChange(ft.toString());
      onInchesChange(inc.toString());
    }
  }, [unit]);

  // When ft/in changes, update cm value
  useEffect(() => {
    if (unit === 'ft' && (feet || inches)) {
      const totalInches =
        (parseFloat(feet) || 0) * 12 + (parseFloat(inches) || 0);
      const cmValue = Math.round(totalInches * 2.54);
      onChange(cmValue.toString());
    }
  }, [feet, inches, unit]);

  const validateFeetInches = (ft, inch) => {
    const combinedValue = `${ft}'${inch}"`;
    const regex = /^(\d{1,2})[\']?((\d)|([0-1][0-2]))?[\"]?$/g;
    const isValid = regex.test(combinedValue);
    setError(isValid ? '' : 'Invalid height format');
    return isValid;
  };

  const handleCmChange = (e) => {
    onChange(e.target.value);
  };

  const handleFtChange = (field, val) => {
    let newFeet = field === 'feet' ? val : feet;
    let newInches = field === 'inches' ? val : inches;

    if (field === 'feet') {
      onFeetChange(val);
    } else {
      onInchesChange(val);
    }

    validateFeetInches(newFeet, newInches);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between mb-[6px]">
        <label className="text-sm-medium text-light_bg_primary opacity-65">
          Height
        </label>
        <div className="flex rounded-full bg-[#F4F4F4] p-0.5">
          <button
            type="button"
            onClick={() => onUnitChange('cm')}
            className={`px-3 py-1 rounded-full text-xs transition-all duration-200 ${
              unit === 'cm'
                ? 'bg-white text-light_bg_primary shadow-sm'
                : 'text-light_bg_primary opacity-65'
            }`}
          >
            cm
          </button>
          <button
            type="button"
            onClick={() => onUnitChange('ft')}
            className={`px-3 py-1 rounded-full text-xs transition-all duration-200 ${
              unit === 'ft'
                ? 'bg-white text-light_bg_primary shadow-sm'
                : 'text-light_bg_primary opacity-65'
            }`}
          >
            ft/in
          </button>
        </div>
      </div>
      <div className="w-full">
        {unit === 'cm' ? (
          <div className="relative w-full">
            <input
              type="number"
              value={value}
              onChange={handleCmChange}
              className={getInputClassName(hasError)}
              placeholder="170"
            />
            <span className="text-md-regular absolute right-3 top-1/2 transform -translate-y-1/2 text-light_bg_primary opacity-65">
              cm
            </span>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex gap-2 w-44">
              <div className="relative flex-1">
                <input
                  type="number"
                  value={feet}
                  onChange={(e) => handleFtChange('feet', e.target.value)}
                  className={getInputClassName(hasError)}
                  placeholder="5"
                />
                <span className="text-md-regular absolute right-3 top-1/2 transform -translate-y-1/2 text-light_bg_primary opacity-65">
                  ft
                </span>
              </div>
              <div className="relative flex-1">
                <input
                  type="number"
                  value={inches}
                  onChange={(e) => handleFtChange('inches', e.target.value)}
                  className={getInputClassName(hasError)}
                  placeholder="8"
                />
                <span className="text-md-regular absolute right-3 top-1/2 transform -translate-y-1/2 text-light_bg_primary opacity-65">
                  in
                </span>
              </div>
            </div>
            {error && (
              <p className="text-sm-medium text-[#F97066] mt-2">{error}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// Main UserDetails Component
const UserDetails = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstname: '',
    dob: '',
    gender: '',
    height: '',
    weight: '',
  });
  const [heightUnit, setHeightUnit] = useState('cm');
  const [feet, setFeet] = useState('');
  const [inches, setInches] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    firstname: false,
    dob: false,
    gender: false,
    height: false,
    weight: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const storedDob = localStorage.getItem('dob');
    if (storedDob) {
      setFormData((prevData) => ({ ...prevData, dob: storedDob }));
    }
  }, []);

  useEffect(() => {
    const { firstname, dob, gender, height, weight } = formData;

    const isValidDOB = validateDOB(dob);
    const isValidHeight = height > 0 && height < 300;
    const isValidWeight = weight > 0 && weight < 150;

    if (firstname && isValidDOB && gender && isValidHeight && isValidWeight) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  const validateDOB = (dob) => {
    if (!dob) {
      setDobError(false);
      return true;
    }

    const [day, month, year] = dob.split('/').map(Number);
    if (!day || !month || !year) {
      setDobError(true);
      return false;
    }

    const userDOB = new Date(year, month - 1, day);
    const today = new Date();
    const age = today.getFullYear() - userDOB.getFullYear();

    const isOldEnough =
      age > 18 ||
      (age === 18 &&
        today >=
          new Date(today.getFullYear(), userDOB.getMonth(), userDOB.getDate()));

    const isTooOld = age <= 100;
    const isValid = isOldEnough && isTooOld;

    setDobError(!isValid);
    return isValid;
  };

  const formatDate = (input) => {
    const cleanedInput = input.replace(/[^\d]/g, '');
    let formattedDate = '';

    for (let i = 0; i < cleanedInput.length && i < 8; i++) {
      if (i === 2 || i === 4) {
        formattedDate += '/';
      }
      formattedDate += cleanedInput[i];
    }

    return formattedDate;
  };

  const handleDateInput = (e) => {
    const input = formatDate(e.target.value);
    setFormData({ ...formData, dob: input });
  };

  const handleDateChange = (e) => {
    const date = new Date(e.target.value).toLocaleDateString('en-GB');
    setFormData({ ...formData, dob: date });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear error state when user starts typing
    setFieldErrors((prev) => ({ ...prev, [name]: false }));
  };

  const handleGenderClick = (gender) => {
    setFormData({ ...formData, gender });
    setFieldErrors((prev) => ({ ...prev, gender: false }));
  };

  const getInputClassName = (fieldName) => {
    return `text-md-regular ${
      fieldErrors[fieldName]
        ? 'shadow-user_details_input_error border-[#F97066] border-[1px]'
        : 'hover:shadow-user_details_input hover:border-secondary_500 hover:border-[1px]'
    } bg-[#ffffff99] rounded-[12px] border-[#00000012] border-[1px] text-gray_800 placeholder-light_bg_primary_0.35 flex-1 min-w-0 block w-full px-3 py-3 pl-[10px] focus:outline-none`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset all field errors
    setFieldErrors({
      firstname: false,
      dob: false,
      gender: false,
      height: false,
      weight: false,
    });

    // Validate each field and collect error messages
    const errors = [];
    const newFieldErrors = {};

    if (!formData.firstname) {
      errors.push('Please enter your first name');
      newFieldErrors.firstname = true;
    }

    if (!validateDOB(formData.dob)) {
      errors.push('Please enter a valid date of birth');
      newFieldErrors.dob = true;
    }

    if (!formData.gender) {
      errors.push('Please select your gender');
      newFieldErrors.gender = true;
    }

    // Validate height based on the selected unit
    if (heightUnit === 'cm') {
      const heightValue = parseFloat(formData.height);
      if (!heightValue || heightValue <= 0 || heightValue >= 300) {
        errors.push('Please enter a valid height (between 1-300 cm)');
        newFieldErrors.height = true;
      }
    } else {
      const feetValue = parseFloat(feet);
      const inchesValue = parseFloat(inches);
      if (
        !feetValue ||
        feetValue < 1 ||
        feetValue > 9 ||
        inchesValue < 0 ||
        inchesValue >= 12
      ) {
        errors.push('Please enter a valid height (feet: 1-9, inches: 0-11)');
        newFieldErrors.height = true;
      }
    }

    // Validate weight
    const weightValue = parseFloat(formData.weight);
    if (!weightValue || weightValue <= 0 || weightValue >= 150) {
      errors.push('Please enter a valid weight (between 1-150 kg)');
      newFieldErrors.weight = true;
    }

    // If there are any errors, show them and return
    if (errors.length > 0) {
      setFieldErrors(newFieldErrors);
      alert(errors.join('\n'));
      return;
    }

    setLoading(true);

    try {
      const goal = localStorage.getItem('Goal');
      const goalDetails = JSON.parse(
        localStorage.getItem('GoalDetails') || '[]'
      );

      // Convert height if in feet/inches
      let heightInCm = formData.height;
      if (heightUnit === 'ft') {
        const totalInches =
          (parseFloat(feet) || 0) * 12 + (parseFloat(inches) || 0);
        heightInCm = Math.round(totalInches * 2.54);
      }

      const dataToSend = {
        ...formData,
        height: Number(heightInCm),
        weight: Number(formData.weight),
        goal: goal,
        goalDetails: goalDetails,
      };

      const userData = JSON.stringify(dataToSend);
      localStorage.setItem('userData', userData);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users`,
        dataToSend,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setLoading(false);
      navigate('/intro-scan', { replace: true });
      console.log('response', response);
      localStorage.setItem('userId', response.data._id);
      localStorage.setItem('dob', formData.dob);
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
      alert('An error occurred while submitting the form. Please try again.');
      navigate('/create-account/user-details', { replace: true });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${PreOnboarding})`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
        backgroundSize: 'contain',
      }}
    >
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '200px',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
        }}
      />
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="flex fixed bg-transparent top-0 left-0 right-0 px-5 items-center">
            <Link to="/">
              <div className="w-6 h-6 p-[5px] flex justify-center items-center bg-white rounded-full">
                <img
                  className="min-h-[18px] min-w-[18px]"
                  src={chevronLeft}
                  alt="Back Button"
                />
              </div>
            </Link>
            <p className="text-light_bg_primary opacity-65 heading-large my-5 mx-auto justify-center">
              Quick Details
            </p>
            <div className="w-6 h-6"></div>
          </div>
          <h1 className="font-inter text-[28px] font-normal leading-[33.6px] tracking-[-0.28px] text-light_bg_primary opacity-85 mt-20">
            Let's get to know you a little better
          </h1>
          <h2 className="text-sm-regular text-[#142A37] opacity-[0.50] mt-2">
            These details help us account for individual factors, ensuring
            accurate, personalised results.
          </h2>
          <div className="flex flex-col mt-10">
            <div className="flex justify-center items-start gap-x-4">
              <div className="flex flex-col">
                <label
                  htmlFor="firstname"
                  className="text-sm-medium text-light_bg_primary opacity-65 mb-[6px]"
                >
                  First Name
                </label>
                <div>
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    className={getInputClassName('firstname')}
                    placeholder="Rohan"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="dob"
                  className="text-sm-medium text-light_bg_primary opacity-65 mb-[6px]"
                >
                  Date Of Birth
                </label>
                <div className="relative">
                  <input
                    type="text"
                    name="dob"
                    id="dob"
                    value={formData.dob}
                    onChange={handleDateInput}
                    className={`${getInputClassName('dob')} pr-[40px]`}
                    placeholder="DD/MM/YYYY"
                    required
                  />
                  <img
                    src={calendarIcon}
                    alt="Calendar Icon"
                    className="absolute max-h-5 max-w-5 right-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                  />
                  <input
                    type="date"
                    onChange={handleDateChange}
                    style={{ zIndex: 1 }}
                    className="absolute max-h-5 max-w-5 right-3 top-1/2 transform -translate-y-1/2 opacity-0 cursor-pointer"
                  />
                </div>
                {dobError && (
                  <p className="text-sm-medium text-[#F97066] mt-2">
                    Invalid date
                  </p>
                )}
              </div>
            </div>
            <label className="text-sm-medium text-light_bg_primary opacity-65 mt-4">
              Gender
            </label>
            <div className="flex justify-between gap-x-4 items-center mt-[6px] min-w-0">
              <button
                type="button"
                onClick={() => handleGenderClick('Male')}
                className={`font-inter text-light_bg_primary text-opacity-85 text-[16px] font-normal leading-[24px] min-w-0 flex justify-center rounded-full ${
                  fieldErrors.gender ? 'border-[#F97066]' : 'border-[#00000012]'
                } border-[1px] px-9 py-2 ${
                  formData.gender === 'Male'
                    ? 'bg-secondary_500 text-white'
                    : 'bg-[#ffffff99] text-gray_500'
                }`}
              >
                Male
              </button>
              <button
                type="button"
                onClick={() => handleGenderClick('Female')}
                className={`font-inter text-light_bg_primary text-opacity-85 text-[16px] font-normal leading-[24px] min-w-0 flex justify-center rounded-full ${
                  fieldErrors.gender ? 'border-[#F97066]' : 'border-[#00000012]'
                } border-[1px] px-9 py-2 ${
                  formData.gender === 'Female'
                    ? 'bg-secondary_500 text-white'
                    : 'bg-[#ffffff99] text-gray_500'
                }`}
              >
                Female
              </button>
              <button
                type="button"
                onClick={() => handleGenderClick('Other')}
                className={`font-inter text-light_bg_primary text-opacity-85 text-[16px] font-normal leading-[24px] min-w-0 flex justify-center rounded-full ${
                  fieldErrors.gender ? 'border-[#F97066]' : 'border-[#00000012]'
                } border-[1px] px-9 py-2 ${
                  formData.gender === 'Other'
                    ? 'bg-secondary_500 text-white'
                    : 'bg-[#ffffff99] text-gray_500'
                }`}
              >
                Other
              </button>
            </div>
            <div className="flex justify-center items-center gap-x-4 mt-6 pb-36">
              <HeightInput
                value={formData.height}
                unit={heightUnit}
                feet={feet}
                inches={inches}
                onUnitChange={setHeightUnit}
                onFeetChange={setFeet}
                onInchesChange={setInches}
                onChange={(value) =>
                  setFormData((prev) => ({ ...prev, height: value }))
                }
                hasError={fieldErrors.height}
              />
              <div className="flex flex-col">
                <label
                  htmlFor="weight"
                  className="text-sm-medium text-light_bg_primary opacity-65 mb-[6px]"
                >
                  Weight
                </label>
                <div className="relative">
                  <input
                    type="number"
                    name="weight"
                    id="weight"
                    value={formData.weight}
                    onChange={handleInputChange}
                    className={getInputClassName('weight')}
                    placeholder="75"
                    required
                  />
                  <span className="text-md-regular absolute right-3 top-1/2 transform -translate-y-1/2 text-light_bg_primary opacity-65">
                    kg
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4 ${
                isFormValid ? 'opacity-100' : 'opacity-50'
              }`}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '100px',
                  background:
                    'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
                }}
                className="z-[-1]"
              />
              <button
                onClick={handleSubmit}
                className="w-full"
                disabled={!isFormValid}
              >
                <CtaButton ButtonText="Submit" LinkTo="" />
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </div>
  );
};

export default UserDetails;
