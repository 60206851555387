import React, { useEffect, useState } from 'react';
import PreOnboarding from '../../assets/PreOnboarding.svg';
import JourneyLayout from '../../components/JourneyLayout';
import Tasks from '../../components/Tasks';
import BlogCard from '../../components/BlogCard';
import Blog1 from '../../assets/Blog1.png';
import Blog2 from '../../assets/Blog2.png';
import Blog3 from '../../assets/Blog3.png';
import HealthFacts from '../../components/HealthFacts';
import Refer from '../../components/Refer';
import BottomNav from '../../components/BottomNav';
import { Link } from 'react-router-dom';
import Loader from '../../utils/Loader';
import facts from '../../components/health_facts.json';
import { sendAmplitudeData } from '../../utils/amplitude';
import axios from 'axios';
import GoalCard from '../../components/GoalCard';
import ActivityCard from '../../components/ActivityCard';
import RecentScanCard from '../../components/RecentScanCard';
import person from '../../assets/Person.svg';
import Clock from '../../assets/Clocknew.svg';
import {
  checkActivityAvailability,
  markActivityComplete,
} from '../../utils/activityUtils';

const Home = () => {
  const [userName, setUserName] = useState('');
  const [showTask, setShowTask] = useState(false);
  const [loading, setLoading] = useState(true);
  const [day, setDay] = useState(null);
  const [showActivity, setShowActivity] = useState(false);

  const fetchScansAndUpdateDay = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/latest/${userId}`
      );
      const latestScan = response.data;

      if (latestScan) {
        const dayFromScan = latestScan.day || 1;
        console.log('Latest Scan:', latestScan);
        if (dayFromScan >= 2) {
          localStorage.setItem('Day', dayFromScan);
        }
        setDay(dayFromScan);
      } else {
        console.log('No scans found for the user.');
      }
    } catch (error) {
      console.error('Error fetching scan data:', error);
    }
  };

  // Check activity availability
  const checkAndUpdateActivityVisibility = () => {
    const isActivityAvailable = checkActivityAvailability();
    setShowActivity(isActivityAvailable);
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const storedDay = localStorage.getItem('Day');

    if (storedDay) {
      setDay(Number(storedDay));
    } else if (userId) {
      fetchScansAndUpdateDay(userId);
    }

    if (userId) {
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.firstname) {
            setUserName(data.firstname);
            localStorage.setItem('Goal', data.goal);
            localStorage.setItem(
              'GoalDetails',
              JSON.stringify([data.goalDetails[0]])
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }

    // Initial activity check
    checkAndUpdateActivityVisibility();

    // Set up polling for activity visibility
    const activityInterval = setInterval(
      checkAndUpdateActivityVisibility,
      60000
    ); // Check every minute

    return () => {
      clearInterval(activityInterval);
    };
  }, []);

  useEffect(() => {
    if (day >= 2 && day < 6) {
      setShowTask(true);
    }
  }, [day]);

  // Handle activity submission
  const handleActivitySubmit = () => {
    console.log('Activity submission started');
    markActivityComplete(); // Store in localStorage
    console.log(
      'Checking localStorage after submission:',
      localStorage.getItem('lastActivitySubmission')
    );
    setShowActivity(false);
  };

  const tasks = [
    {
      id: 1,
      task: "Complete today's scan",
    },
    {
      id: 2,
      task: 'Check upcoming questions',
    },
  ];

  const handleBlog1Click = () => {
    sendAmplitudeData('TAP_BLOG_CARD', {
      article: 'Learn about cardio fitness',
    });
  };

  const handleBlog2Click = () => {
    sendAmplitudeData('TAP_BLOG_CARD', {
      article: 'Lower your resting heart rate',
    });
  };

  const handleBlog3Click = () => {
    sendAmplitudeData('TAP_BLOG_CARD', {
      article: 'Heart rate and anxiety',
    });
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${PreOnboarding})`,
          backgroundRepeat: 'repeat-x',
          position: 'relative',
          backgroundSize: 'contain',
        }}
      >
        <JourneyLayout>
          <div className="flex justify-between items-center mt-4 mb-5">
            <h1 className="heading-small text-[#F63D68]">Heartcheck</h1>
            {/* <div className="p-2 border border-[#E5E7EB] rounded-full">
              <img src={person} alt="profile" />
            </div> */}
          </div>

          {showTask && (
            <div className="flex-col mt-10">
              <h1 className="text-light_bg_primary display-title-medium mb-4">
                Today's tasks
              </h1>
              <Tasks tasks={tasks} />
            </div>
          )}

          {showActivity && (
            <div className="mt-4">
              <ActivityCard onSubmit={handleActivitySubmit} />
            </div>
          )}

          <h1 className="text-xl mt-4 font-inter leading-6">
            Your health score
          </h1>

          <div className="mt-4">
            <GoalCard />
          </div>

          <div className="mt-4">
            <RecentScanCard />
          </div>

          <h1 className="display-title-medium text-light_bg_primary opacity-95 mt-10 mb-4">
            Explore wellness
          </h1>
          <Link to="/home/blog1" onClick={handleBlog1Click}>
            <BlogCard
              time={10}
              title="Learn about cardio fitness"
              description="How it's measured, why it matters, and how to improve yours."
              image={Blog1}
            />
          </Link>

          <Link to="/home/blog2" onClick={handleBlog2Click}>
            <BlogCard
              time={1}
              title="Lower your resting heart rate"
              description="How it's measured, why it matters, and how to improve yours."
              image={Blog2}
            />
          </Link>

          <Link to="/home/blog3" onClick={handleBlog3Click}>
            <BlogCard
              time={1}
              title="Heart rate and anxiety"
              description="How it's measured, why it matters, and how to improve yours."
              image={Blog3}
            />
          </Link>

          <h2 className="text-light_bg_primary display-title-medium mb-4 mt-10">
            Health facts
          </h2>
          <div className="mb-8">
            <HealthFacts facts={facts} day={day} />
          </div>
        </JourneyLayout>
        <Refer />
        <BottomNav />
      </div>
    </>
  );
};

export default Home;
