import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MetricDetailLayout from './MetricDetailLayout';
import HeartEnergyDetailModal from '../../components/Modals/HeartEnergyDetailModal';
import { getAdvice } from '../../utils/adviceLogic';

const HeartEnergyDetail = () => {
  const location = useLocation();
  const { value } = location.state?.data || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const metricInfo = {
    title: 'Heart energy',
    subtitle:
      'The optimal measure of how your heart health aligns with the recommended standards for your age and gender',
    value,
    unit: '',
    normalRange: 'Typical range for adults lies between 35-87.',
    rangeDescription:
      "Your's is in the higher range. This is a sign of good heart rate.",
    isSuccess: true,
    advice: getAdvice('Heart energy', value),
    symptoms: [
      'A resting heart rate under 50 bpm is of concern. Symptoms can range from dizziness, weakness, etc.',
      "Above 84 bpm can mean there's a heart issue you need to be aware of.",
    ],
    learnMoreText: 'Learn more about Heart energy',
    onLearnMoreClick: () => setIsModalOpen(true),
  };

  return (
    <>
      <MetricDetailLayout metricInfo={metricInfo} />
      <HeartEnergyDetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default HeartEnergyDetail;
