import React, { useState } from 'react';
import chevronLeft from '../../assets/chevronLeft.svg';
import { Link, useNavigate } from 'react-router-dom';
import xclose from '../../assets/x-close.svg';
import Layout from '../../components/Layout';

const Goal = () => {
  const [selectedGoal, setSelectedGoal] = useState(null);
  const navigate = useNavigate();

  const handleSelectGoal = (goal) => {
    setSelectedGoal(goal);
    localStorage.setItem('Goal', goal);
    setTimeout(() => {
      navigate('/goal-detail');
    }, 200);
  };

  const goalData = [
    {
      id: 1,
      title: 'Get healthy and fit',
      description: 'Be energetic, improve sleep, fitness prep, etc.',
    },
    {
      id: 2,
      title: 'Control a condition',
      description: 'BP, diabetes, high cholesterol, stress, etc.',
    },
    {
      id: 3,
      title: 'Recover from an ailment',
      description: 'Fever, pneumonia, stress, obesity, etc.',
    },
  ];

  return (
    <div
      className="fixed inset-0 overflow-hidden"
      style={{
        background:
          'linear-gradient(180deg, rgba(254, 205, 214, 0.60) -31.65%, rgba(254, 246, 238, 0.60) 130.06%)',
      }}
    >
      <div className="flex bg-transparent top-0 left-0 right-0 items-center px-5">
        <Link to="/">
          <div className="w-8 h-8 p-[5px] flex justify-center items-center bg-white rounded-full">
            <img
              className="max-h-[18px] max-w-[18px]"
              src={chevronLeft}
              alt="Back Button"
            />
          </div>
        </Link>
        <p className="text-light_bg_primary opacity-65 heading-large my-5 mx-auto justify-center">
          Goals
        </p>
        <Link to="/">
          <div className="w-8 h-8 p-[5px] flex justify-center items-center bg-white rounded-full">
            <img
              className="max-h-[18px] max-w-[18px]"
              src={xclose}
              alt="Close"
            />
          </div>
        </Link>
      </div>
      <Layout className="h-[calc(100%-80px)]">
        <div className="h-full">
          <h1 className="display-title text-light_bg_primary mt-10">
            Let's start with your goal
          </h1>
          <p className="text-sm-regular text-light_bg_secondary mt-2">
            Choose the one that best describes what you're looking to achieve
          </p>
          <div className="mt-8">
            {goalData.map((goal) => (
              <div
                key={goal.id}
                className={`py-[18px] flex items-center justify-between px-4 rounded-[12px] shadow-result_card mb-4 cursor-pointer transition-all duration-200 ${
                  selectedGoal === goal.title
                    ? 'bg-[#111927] text-white border-2'
                    : 'bg-base_white border-[1px] border-[#98A2B3] text-light_bg_primary'
                }`}
                onClick={() => handleSelectGoal(goal.title)}
              >
                <div className="flex flex-col">
                  <h3
                    className={`text-md-regular ${
                      selectedGoal === goal.title
                        ? 'text-white'
                        : 'text-light_bg_primary'
                    }`}
                  >
                    {goal.title}
                  </h3>
                  <p
                    className={`text-sm-regular ${
                      selectedGoal === goal.title
                        ? 'text-[#FCFCFDA3]'
                        : 'text-light_bg_secondary'
                    }`}
                  >
                    {goal.description}
                  </p>
                </div>

                <img
                  className="max-h-[18px] max-w-[18px] transform rotate-180"
                  src={chevronLeft}
                  alt="Back Button"
                />
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Goal;
