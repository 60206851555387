import React, { useState } from 'react';
import MetricDetailLayout from './MetricDetailLayout';
import { useLocation } from 'react-router-dom';
import { getAdvice } from '../../utils/adviceLogic';
import DiabetesRiskDetailModal from '../../components/Modals/DiabetesRiskDetailModal';

const DiabetesDetail = () => {
  const location = useLocation();
  const { value } = location.state?.data || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const metricInfo = {
    title: 'Diabetes risk score',
    subtitle: 'Indicates your risk of developing diabetes',
    value,
    unit: 'Out of 100',
    normalRange: 'A lower score indicates a lower risk of developing diabetes.',
    rangeDescription:
      value <= 33
        ? 'Your score indicates a lower risk of developing diabetes.'
        : value <= 66
        ? 'You are at moderate risk of developing diabetes.'
        : 'Your score indicates a high risk of developing diabetes.',
    isSuccess: value <= 33,
    advice: getAdvice('Diabetes risk score', value),
    symptoms: [
      'Higher scores may indicate an increased risk of developing type 2 diabetes.',
      'Lifestyle changes can help reduce your risk.',
    ],
    learnMoreText: 'Learn more about Diabetes risk score',
    onLearnMoreClick: () => setIsModalOpen(true),
  };

  return (
    <>
      <MetricDetailLayout metricInfo={metricInfo} />
      <DiabetesRiskDetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        heartRate={value}
      />
    </>
  );
};

export default DiabetesDetail;
