import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import chevronLeft from '../../assets/chevronLeft.svg';
import PreOnboarding from '../../assets/PreOnboarding.svg';
import CtaButton from '../../components/CtaButton';
import Layout from '../../components/Layout';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [showOtpField, setShowOtpField] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [resendTimer, setResendTimer] = useState(0);
  const [userId, setUserId] = useState('');
  const [dob, setDob] = useState(''); // Store userId from email search
  const otpBoxReference = useRef([]);
  const navigate = useNavigate();
  const [buttonOffset, setButtonOffset] = useState(0);

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleContinueClick = async () => {
    if (!showOtpField) {
      if (!isValidEmail(email)) {
        alert('Please enter a valid email address.');
        return;
      }
      try {
        // Check if the email exists in the database
        const searchResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/email/${email}`
        );
        if (searchResponse.status === 200) {
          // Email exists, store the userId from the search response
          setUserId(searchResponse.data._id);
          setDob(searchResponse.data.dob); // Store userId in state
          // Now send the OTP
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/send-otp`,
            { email }
          );
          if (response.status === 200) {
            setShowOtpField(true);
            startResendTimer();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          alert('Email not found. Please sign up first.');
        } else {
          console.error('Error searching for email:', error);
        }
      }
    } else {
      if (otp.some((digit) => digit === '')) {
        alert('Please fill all OTP fields.');
        return;
      }
      try {
        // Verify OTP
        const otpResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/verify-otp`,
          {
            email,
            otp: otp.join(''),
          }
        );
        if (otpResponse.status === 200) {
          // OTP verified, log the user in and store the userId from the email search
          localStorage.setItem('Login', 'true');
          localStorage.setItem('userId', userId); // Use the userId stored in state
          localStorage.setItem('dob', dob); // Use the dob stored in state
          navigate('/home', { replace: true });
        } else {
          alert('Invalid OTP, please try again.');
        }
      } catch (error) {
        console.error('Error verifying OTP:', error);
      }
    }
  };

  const handleChange = (value, index) => {
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      let newArr = [...otp];
      newArr[index] = value;
      setOtp(newArr);
      if (value && index < 5 && otpBoxReference.current[index + 1]) {
        otpBoxReference.current[index + 1].focus();
      }
    }
  };

  const handleBackspaceAndEnter = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
      let newArr = [...otp];
      newArr[index] = ''; // Clear the previous field when backspace is pressed
      setOtp(newArr);
    }
    if (e.key === 'Enter' && e.target.value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pasteData = e.clipboardData
      .getData('text')
      .trim()
      .slice(0, otp.length - index)
      .split('');
    const newOtp = [...otp];
    for (let i = index; i < otp.length; i++) {
      if (pasteData.length > 0) {
        newOtp[i] = pasteData.shift();
      }
    }
    setOtp(newOtp);
    const lastIndex = otp.length - 1;
    if (otpBoxReference.current[lastIndex]) {
      otpBoxReference.current[lastIndex].focus();
    }
  };

  const startResendTimer = () => setResendTimer(60);

  const handleResendOTP = async () => {
    if (resendTimer === 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/send-otp`,
          { email }
        );
        if (response.status === 200) {
          startResendTimer();
        }
      } catch (error) {
        console.error('Error resending OTP:', error);
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      // Initiate Google OAuth login by redirecting to the auth URL
      window.location.href = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/google/login`;
      //window.location.href = `http://localhost:5001/api/google/login`;
    } catch (error) {
      console.error('Error during Google login:', error);
      alert('Google login failed. Please try again.');
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');
    const success = params.get('success');
    const userId = params.get('userId');
    const dob = params.get('dob');

    console.log('data from params', success);

    if (error) {
      // Show error message without navigation
      alert(error);
    } else if (success === 'true') {
      try {
        // Parse the user data and set in localStorage
        // const userData = JSON.parse(decodeURIComponent(success));
        localStorage.setItem('Login', 'true');
        localStorage.setItem('userId', userId);
        localStorage.setItem('dob', dob);
        navigate('/home', { replace: true });
      } catch (error) {
        console.error('Error processing login data:', error);
        alert('Login failed. Please try again.');
      }
    }
  }, [navigate]);

  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  const isEmailFieldEmpty = !email || !isValidEmail(email);
  const isOtpIncomplete = otp.some((digit) => digit === '');

  // Add keyboard detection
  useEffect(() => {
    if (!window.visualViewport) return;

    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const viewportHeight = window.visualViewport.height;

      // If viewport is significantly smaller than window height, keyboard is likely visible
      if (windowHeight - viewportHeight > 100) {
        // Set offset to the difference between window bottom and viewport bottom
        const offsetFromBottom =
          windowHeight - (window.visualViewport.offsetTop + viewportHeight);
        setButtonOffset(offsetFromBottom);
      } else {
        setButtonOffset(0);
      }
    };

    window.visualViewport.addEventListener('resize', handleResize);
    window.visualViewport.addEventListener('scroll', handleResize);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
      window.visualViewport.removeEventListener('scroll', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${PreOnboarding})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Layout>
        <div className="relative flex items-center justify-center my-5">
          <Link to="/intro-scan/results" className="absolute shadow-sm left-0">
            <div className="w-6 h-6 p-[14px] shadow-sm flex justify-center items-center bg-white rounded-full">
              <img
                className="min-h-[18px] min-w-[18px]"
                src={chevronLeft}
                alt="Back Button"
              />
            </div>
          </Link>
          <p className="text-light_bg_primary opacity-65 heading-large">
            Login
          </p>
        </div>
        <h1 className="display-title mt-6 text-light_bg_primary opacity-85 mb-1">
          {showOtpField ? 'Enter OTP' : 'Login'}
        </h1>
        <p className="text-sm-regular text-light_bg_primary opacity-65">
          {showOtpField ? (
            <>
              A 6-digit verification code was sent to your email address{' '}
              <span className="text-sm-regular text-light_bg_primary opacity-85">
                {email}
              </span>
            </>
          ) : (
            'Enter your email address to get started. We will send an OTP for verification'
          )}
        </p>
        {showOtpField ? (
          <div className="flex flex-col mt-10">
            <label
              htmlFor="otp"
              className="text-sm-medium text-light_bg_primary text-opacity-65 mb-[6px]"
            >
              OTP
            </label>
            <div className="flex flex-wrap justify-between">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  value={digit}
                  type="tel"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  maxLength={1}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                  onPaste={(e) => handlePaste(e, index)}
                  ref={(reference) =>
                    (otpBoxReference.current[index] = reference)
                  }
                  className={`heading-large text-light_bg_primary text-center bg-[#fff] backdrop-blur-sm border-[#F3F4F6] border-[1px] gap-x-4 box w-[calc(16.66%-8px)] h-auto p-3 rounded-xl focus:outline-none ${
                    digit ? 'border-secondary_500' : ''
                  } focus-within:border-[1px] focus-within:border-secondary_500`}
                />
              ))}
            </div>
            <p className="text-sm-regular mt-[6px] text-light_bg_primary text-opacity-65">
              {resendTimer > 0 ? (
                `Resend code in ${Math.floor(resendTimer / 60)
                  .toString()
                  .padStart(2, '0')}:${(resendTimer % 60)
                  .toString()
                  .padStart(2, '0')}`
              ) : (
                <span
                  onClick={handleResendOTP}
                  className="cursor-pointer text-sm-regular text-[#F63D68]"
                >
                  Resend code
                </span>
              )}
            </p>
          </div>
        ) : (
          <>
            <div className="flex flex-col mt-10">
              <label
                htmlFor="email"
                className="text-sm-medium text-light_bg_primary opacity-65 mb-[6px]"
              >
                Email Address
              </label>
              <div className="flex rounded-[12px] border-[1px] border-[#98A2B3] focus-within:border-[1px] focus-within:border-[#111927] focus-within:border-opacity-100">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-md-regular text-light_bg_primary rounded-[12px] bg-[#ffffff] placeholder-gray_500 flex-1 min-w-0 block w-full px-3 py-3 focus:outline-none"
                  placeholder="yourname@example.com"
                />
              </div>
            </div>

            <div className="mt-4">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-light_bg_primary text-opacity-65 bg-white">
                    Or
                  </span>
                </div>
              </div>

              <button
                onClick={handleGoogleLogin}
                className="mt-4 w-full flex items-center justify-center gap-3 px-3 py-3 border border-gray-300 rounded-xl bg-white text-md-regular text-light_bg_primary hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary_500"
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24">
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                </svg>
                Login with Google
              </button>
            </div>
          </>
        )}

        <div
          className="w-full max-w-[393px] mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 bg-white"
          style={{
            bottom: buttonOffset,
            transition: 'bottom 0.3s',
          }}
        >
          <p className="text-xs-medium text-gray_400 text-center mb-3">
            By continuing, you agree to our{' '}
            <span className="text-gray_800">Terms of Service</span>
          </p>
          <div
            onClick={handleContinueClick}
            className={`${
              (!showOtpField && isEmailFieldEmpty) ||
              (showOtpField && isOtpIncomplete)
                ? 'opacity-25 pointer-events-none'
                : 'opacity-100'
            }`}
          >
            <CtaButton ButtonText="Continue" LinkTo={showOtpField ? '' : ''} />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Login;
