import React, { useState, useEffect } from 'react';
import PreOnboarding from '../../assets/PreOnboarding.svg';
import Layout from '../../components/Layout';
import chevronLeft from '../../assets/chevronLeft.svg';
import { Link, useNavigate } from 'react-router-dom';
import xclose from '../../assets/x-close.svg';

const GoalDetail = () => {
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const Navigate = useNavigate();

  useEffect(() => {
    const savedGoal = localStorage.getItem('Goal');
    setSelectedGoal(savedGoal);
  }, []);

  const goalDescription = [
    {
      id: 1,
      title: 'Get healthy and fit',
      heading: 'What are your health and longevity goals?',
      options: [
        'Increase energy through the day',
        'Improve sleep',
        'Prepare for an event like a trek, marathon, etc',
        'Improve mindfullness',
        'Be healthy',
      ],
    },
    {
      id: 2,
      title: 'Control a condition',
      heading: 'What all do you want to control?',
      options: [
        'Diabetes',
        'High Cholesterol',
        'Hypertension (High BP)',
        'Stress & Anxiety',
        'Obesity',
      ],
    },
    {
      id: 3,
      title: 'Recover from an ailment',
      heading: 'What are you recovering from?',
      options: [
        'Injury',
        'Fever, pneumonia, etc',
        'Diabetes (Type 2)',
        'Obesity',
      ],
    },
  ];

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((item) => item !== option)
        : [...prevOptions, option]
    );
  };

  const handleSetGoal = () => {
    if (selectedOptions.length === 0) {
      alert('Please select at least one option.');
    } else {
      localStorage.setItem('GoalDetails', JSON.stringify(selectedOptions));
      Navigate('/create-account/user-details');
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${PreOnboarding})`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
        backgroundSize: 'contain',
      }}
    >
      <div className="flex bg-transparent top-0 left-0 right-0 px-5 items-center">
        <Link to="/goal">
          <div className="w-8 h-8 p-[5px] flex justify-center items-center bg-white rounded-full">
            <img
              className="max-h-[18px] max-w-[18px]"
              src={chevronLeft}
              alt="Back Button"
            />
          </div>
        </Link>
        <p className="text-light_bg_primary opacity-65 heading-large my-5 mx-auto justify-center ">
          Goals
        </p>
        <Link to="/">
          <div className="w-8 h-8 p-[5px] flex justify-center items-center bg-white rounded-full">
            <img
              className="max-h-[18px] max-w-[18px]"
              src={xclose}
              alt="Close"
            />
          </div>
        </Link>
      </div>
      <Layout>
        <div>
          {goalDescription.map((goal) => {
            if (goal.title === selectedGoal) {
              return (
                <div key={goal.id}>
                  <h1 className="display-title text-light_bg_primary">
                    {goal.heading}
                  </h1>
                  <p className="text-sm-regular text-light_bg_primary mt-2 mb-14">
                    <span className="text-light_bg_secondary">Select 1</span> or
                    more options
                  </p>

                  {goal.options.map((option, index) => {
                    const isChecked = selectedOptions.includes(option);
                    return (
                      <React.Fragment key={option}>
                        <div
                          className="flex justify-between items-center cursor-pointer hover:bg-gray-50 py-2 px-1 rounded"
                          onClick={() => handleCheckboxChange(option)}
                        >
                          <p className="text-sm-regular text-light_bg_primary flex-grow">
                            {option}
                          </p>
                          <div className="relative ml-2">
                            <input
                              className="h-5 w-5 appearance-none bg-base_white border-[#E5E7EB] border-[1px] rounded-[6px] checked:bg-secondary_500 cursor-pointer"
                              type="checkbox"
                              checked={isChecked}
                              onChange={(e) => {
                                // Prevent the parent div's onClick from firing
                                e.stopPropagation();
                                handleCheckboxChange(option);
                              }}
                            />
                            {isChecked && (
                              <span className="absolute left-0 right-0 top-[2px] flex items-center justify-center pointer-events-none">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <path
                                    d="M12.1016 4.6807L5.8516 10.9307C5.79717 10.9853 5.73248 11.0287 5.66126 11.0582C5.59004 11.0878 5.51368 11.103 5.43656 11.103C5.35945 11.103 5.28309 11.0878 5.21186 11.0582C5.14064 11.0287 5.07596 10.9853 5.02152 10.9307L2.28715 8.19633C2.23265 8.14183 2.18941 8.07712 2.15991 8.00591C2.13042 7.93469 2.11523 7.85837 2.11523 7.78129C2.11523 7.70421 2.13042 7.62788 2.15991 7.55667C2.18941 7.48546 2.23265 7.42075 2.28715 7.36625C2.34165 7.31175 2.40636 7.26851 2.47757 7.23902C2.54878 7.20952 2.62511 7.19434 2.70219 7.19434C2.77927 7.19434 2.85559 7.20952 2.92681 7.23902C2.99802 7.26851 3.06272 7.31175 3.11723 7.36625L5.43705 9.68608L11.2725 3.8516C11.3826 3.74153 11.5319 3.67969 11.6875 3.67969C11.8432 3.67969 11.9925 3.74153 12.1026 3.8516C12.2127 3.96168 12.2745 4.11097 12.2745 4.26664C12.2745 4.42231 12.2127 4.57161 12.1026 4.68168L12.1016 4.6807Z"
                                    fill="white"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        </div>
                        {index < goal.options.length - 1 && (
                          <hr className="text-[#E5E7EB]" />
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            }
            return null;
          })}
        </div>
        <div
          className="fixed bottom-0 left-0 right-0 p-4 bg-white shadow-md"
          onClick={handleSetGoal}
        >
          <div className="max-w-lg mx-auto flex justify-end items-center">
            <button className="heading-small px-5 min-h-12 py-3 bg-primary_500 text-dark_bg_primary text-opacity-[0.92] font-inter rounded-[8px] w-full shadow-xs">
              Set my goal
            </button>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default GoalDetail;
