import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import image1 from '../../assets/Image1.png';
import Logo from '../../assets/Frame.svg';

function SignUp() {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const splashTimeout = setTimeout(() => {
      setShowContent(true);
    }, 3000);

    return () => clearTimeout(splashTimeout);
  }, []);

  if (!showContent) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-splash transition-opacity duration-500 ease-in-out">
        <div className="flex flex-col gap-x-3 items-center">
          <img src={Logo} alt="logo" className="w-[43px] h-[43px]" />
          <p className="text-[20.7px] font-normal font-inter text-[#FD6F8E]">
            heartcheck
          </p>
          <p className="heading-large text-light_bg_secondary mx-10 text-center mt-4">
            Achieve your health goals and take care of your heart
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[100dvh] w-full flex flex-col overflow-hidden">
      <div className="relative flex-1">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${image1})`,
            maskImage: `linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 1) 100%)`,
            WebkitMaskImage: `linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 1) 100%)`,
          }}
        />
      </div>

      <div className="relative">
        <div className="w-full max-w-[393px] mx-auto px-5 pb-3">
          <div className="relative z-20">
            <h1 className="display-title mb-2 text-light_bg_primary opacity-85">
              Get closer to your health goals
            </h1>
            <div className="flex gap-x-4 z-10 mt-[35px] flex-col">
              <Link className="w-full" to="/goal">
                <button className="heading-small text-dark_bg_primary text-opacity-[0.92] flex w-full min-h-12 max-w-[353px] bg-primary_500 justify-center items-center py-3 rounded-lg mb-3 shadow-xs">
                  Choose your goal
                </button>
              </Link>
              <p className="text-md-regular text-center text-light_bg_secondary">
                Already have an account?{' '}
                <Link to="/login">
                  <span className="text-[#F63D68] underline">Log in</span>
                </Link>
              </p>
            </div>
          </div>

          <div
            style={{
              background:
                'linear-gradient(180deg, #FEF6EE 0%, #FECDD6 25%, #FECDD6 100%)',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: -1,
              filter: 'blur(48px)',
              opacity: 1,
            }}
            className="w-full h-[1180px] z-20"
          />
        </div>
      </div>
    </div>
  );
}

export default SignUp;
