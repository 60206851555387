import React, { useState } from 'react';
import MetricDetailLayout from './MetricDetailLayout';
import { useLocation } from 'react-router-dom';
import { getAdvice } from '../../utils/adviceLogic';
import HeartRateDetailModal from '../../components/Modals/HeartRateDetailModal';

const HeartRateDetail = () => {
  const location = useLocation();
  const { value } = location.state?.data || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const metricInfo = {
    title: 'Resting heart rate',
    subtitle: 'The speed at which your heart beats',
    value,
    unit: 'Beats/min',
    normalRange:
      'Adults at rest have a normal resting heart rate between 50 to 84 bpm.',
    rangeDescription:
      value >= 56 && value <= 80
        ? 'Yours lie in typical range of resting adults.'
        : 'Yours lie outside the typical range of resting adults.',
    isSuccess: value >= 56 && value <= 80,
    advice: getAdvice('Resting heart rate', value),
    symptoms: [
      'A resting heart rate under 50 bpm is of concern. Symptoms can range from dizziness, weakness, etc.',
      "Above 84 bpm can mean there's a heart issue you need to be aware of.",
    ],
    learnMoreText: 'Learn more about Resting heart rate',
    onLearnMoreClick: () => setIsModalOpen(true),
  };

  return (
    <>
      <MetricDetailLayout metricInfo={metricInfo} />
      <HeartRateDetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        heartRate={value}
      />
    </>
  );
};

export default HeartRateDetail;
