import React, { useState } from 'react';
import MetricDetailLayout from './MetricDetailLayout';
import { useLocation } from 'react-router-dom';
import { getAdvice } from '../../utils/adviceLogic';
import CrrRateDetailModal from '../../components/Modals/CrrRateDetailModal';
const CrrDetail = () => {
  const location = useLocation();
  const { value } = location.state?.data || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const metricInfo = {
    title: 'Cardiorespiratory rhythm',
    subtitle: 'Coordination between your heart rate and respiratory rate',
    value,
    unit: 'CRR = HR/RR',
    normalRange: 'Normal CRR ranges from 2 - 6.',
    rangeDescription:
      value >= 3 && value <= 6
        ? 'You have a normal coordination between your heart rate & respiratory rate.'
        : "You're an irregular coordination between your heart rate & respiratory rate.",
    isSuccess: value >= 3 && value <= 6,
    advice: getAdvice('Cardiorespiratory rhythm', value),
    symptoms: [
      'An irregular CRR may indicate potential cardiovascular or respiratory issues.',
      'Consult a healthcare professional if you experience any related symptoms.',
    ],
    learnMoreText: 'Learn more about Cardiorespiratory rhythm',
    onLearnMoreClick: () => setIsModalOpen(true),
  };

  return (
    <>
      <MetricDetailLayout metricInfo={metricInfo} />
      <CrrRateDetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        heartRate={value}
      />
    </>
  );
};

export default CrrDetail;
