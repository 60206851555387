import React from 'react';
import refer from '../../src/assets/Refer.png';

const Refer = () => {
  const handleShare = async () => {
    const url = 'https://www.heartcheck.app';
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Join me on Heartcheck!',
          text: 'Track your heart health and stay informed with personalized insights.',
          url: url,
        });
      } catch (err) {
        if (err.name !== 'AbortError') {
          handleCopyUrl();
        }
      }
    } else {
      handleCopyUrl();
    }
  };

  const handleCopyUrl = async () => {
    const url = 'https://www.heartcheck.app';

    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(url);
        alert('URL copied to clipboard!');
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = url;
        // Prevent keyboard from showing up
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.opacity = '0';
        textArea.setAttribute('readonly', ''); // Prevent keyboard on mobile

        document.body.appendChild(textArea);
        textArea.focus({ preventScroll: true }); // Prevent page jump
        textArea.select();

        try {
          document.execCommand('copy');
          alert('URL copied to clipboard!');
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
        document.body.removeChild(textArea);
      }
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <div className="w-full pb-26">
      <div
        style={{
          backgroundImage: `url(${refer})`,
          backgroundRepeat: 'no-repeat',
        }}
        className="w-full pb-[100.36%] bg-cover rounded-2xl bg-center cursor-pointer"
        onClick={handleShare}
      />
    </div>
  );
};

export default Refer;
