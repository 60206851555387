import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProgressGauge from './ProgressGauge';

const ExerciseSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      d="M8.99376 2.13004C8.91993 1.72246 8.52843 1.44458 8.11931 1.50938C7.74689 1.56836 7.48826 1.89098 7.50055 2.25582C5.68017 2.7035 4.52471 3.60656 3.84135 4.71593C3.15762 5.82589 2.9971 7.06035 2.9971 8.05743V8.53243L1.92611 10.0155C1.18632 11.0399 1.43499 12.4725 2.47673 13.1877L11.372 19.2946C12.9962 20.4097 14.9202 21.0065 16.8904 21.0065H19.1397C20.9939 21.0065 22.4971 19.5034 22.4971 17.6492C22.4971 17.4724 22.4829 17.2992 22.4557 17.1299C22.1935 15.4967 20.7708 14.3674 19.4699 13.7931C18.9885 13.5806 18.5824 13.2893 18.3162 12.8975L15.169 7.48149C15.5583 7.40091 15.8125 7.02837 15.7408 6.63261C15.667 6.22503 15.2755 5.94714 14.8664 6.01194C12.0629 6.45597 9.45131 4.65588 8.99376 2.13004ZM5.11849 5.50264C5.38458 5.07067 5.77123 4.65485 6.3448 4.3113L8.09992 7.35127C8.43155 7.92567 8.19682 8.66085 7.59367 8.93679L5.57877 9.8586L4.49711 9.11404L4.4971 8.05743C4.4971 7.20064 4.63881 6.28135 5.11849 5.50264ZM10.9454 13.5527L7.35094 11.0785L11.0471 9.12995C11.5819 8.84805 12.2438 9.03912 12.546 9.56263L13.3541 10.9623C13.6733 11.515 13.4691 12.2223 12.9045 12.52L10.9454 13.5527ZM3.5721 10.2983L13.0302 16.8088C14.156 17.5838 15.4906 17.9988 16.8575 17.9988H20.9642C20.8007 18.8574 20.046 19.5065 19.1397 19.5065H16.8904C15.2233 19.5065 13.5953 19.0015 12.221 18.058L3.32571 11.9511C2.97846 11.7127 2.89558 11.2351 3.14217 10.8937L3.5721 10.2983Z"
      fill="#66C61C"
    />
  </svg>
);

const DietSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      d="M17.998 3C18.243 3.00003 18.4794 3.08996 18.6624 3.25272C18.8455 3.41547 18.9624 3.63975 18.991 3.883L18.998 4V20C18.9978 20.2549 18.9002 20.5 18.7252 20.6854C18.5502 20.8707 18.3111 20.9822 18.0567 20.9972C17.8022 21.0121 17.5517 20.9293 17.3562 20.7657C17.1608 20.6021 17.0352 20.3701 17.005 20.117L16.998 20V15H15.998C15.7531 15 15.5167 14.91 15.3337 14.7473C15.1506 14.5845 15.0337 14.3603 15.005 14.117L14.998 14V8C14.998 5.79 16.498 3 17.998 3ZM11.998 3C12.243 3.00003 12.4794 3.08996 12.6624 3.25272C12.8455 3.41547 12.9624 3.63975 12.991 3.883L12.998 4V9C12.9979 9.88687 12.7032 10.7486 12.1602 11.4498C11.6172 12.151 10.8567 12.6519 9.99805 12.874V20C9.99776 20.2549 9.90017 20.5 9.7252 20.6854C9.55023 20.8707 9.31109 20.9822 9.05665 20.9972C8.80221 21.0121 8.55167 20.9293 8.35621 20.7657C8.16076 20.6021 8.03515 20.3701 8.00505 20.117L7.99805 20V12.874C7.1735 12.6608 6.43845 12.1902 5.89981 11.5305C5.36117 10.8708 5.04702 10.0565 5.00305 9.206L4.99805 9V4C4.99833 3.74512 5.09593 3.49997 5.2709 3.31463C5.44586 3.1293 5.685 3.01777 5.93944 3.00283C6.19388 2.98789 6.44443 3.07067 6.63988 3.23426C6.83533 3.39786 6.96094 3.6299 6.99105 3.883L6.99805 4V9C6.99806 9.35106 7.09047 9.69594 7.26601 9.99997C7.44155 10.304 7.69402 10.5565 7.99805 10.732V4C7.99833 3.74512 8.09593 3.49997 8.2709 3.31463C8.44586 3.1293 8.685 3.01777 8.93944 3.00283C9.19388 2.98789 9.44443 3.07067 9.63988 3.23426C9.83533 3.39786 9.96094 3.6299 9.99105 3.883L9.99805 4L9.99905 10.732C10.2781 10.5707 10.514 10.3443 10.6868 10.0722C10.8595 9.80004 10.9639 9.49018 10.991 9.169L10.998 9V4C10.998 3.73478 11.1034 3.48043 11.2909 3.29289C11.4785 3.10536 11.7328 3 11.998 3Z"
      fill="#EAAA08"
    />
  </svg>
);

const HydratedSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <g clipPath="url(#clip0_14601_21335)">
      <path
        d="M13.0642 2.65627C13.582 1.75947 14.7287 1.4522 15.6255 1.96997L18.8731 3.84497C19.7699 4.36274 20.0771 5.50947 19.5594 6.40627C19.1571 7.10302 18.3752 7.44392 17.6258 7.31851C17.3751 8.05221 17.5016 8.85163 17.7879 9.89895C18.6361 11.8039 18.5927 14.0777 17.4688 16.0243C17.454 16.05 17.4389 16.0757 17.4237 16.1012L17.4136 16.1195C17.4071 16.1311 17.4005 16.1426 17.3938 16.1542L14.0938 21.8699C13.2654 23.3048 11.4306 23.7964 9.99576 22.968C9.38107 22.6131 8.93982 22.0734 8.70103 21.4606C8.05089 21.5602 7.36285 21.4479 6.74816 21.093C6.13347 20.7381 5.69222 20.1984 5.45343 19.5856C4.8033 19.6852 4.11526 19.5729 3.50057 19.218C2.06569 18.3896 1.57407 16.5548 2.40249 15.1199L5.70249 9.40417C5.71265 9.38658 5.72295 9.36912 5.73341 9.35178C5.74791 9.32588 5.76261 9.30004 5.77749 9.27427C6.60777 7.83619 7.88896 6.81825 9.33833 6.29759C9.36067 6.28846 9.38358 6.28036 9.40701 6.27334C9.77565 6.14536 10.1549 6.04935 10.5403 5.98651C11.7704 5.67824 12.6354 5.39435 13.1992 4.73433C12.7524 4.15071 12.6738 3.33245 13.0642 2.65627ZM14.8755 3.26901C14.6961 3.16546 14.4668 3.22691 14.3632 3.40627C14.2597 3.58563 14.3211 3.81497 14.5005 3.91853L17.7481 5.79353C17.9274 5.89708 18.1568 5.83563 18.2603 5.65627C18.3639 5.47691 18.3024 5.24756 18.1231 5.14401L14.8755 3.26901ZM11.3874 7.31735L16.3034 10.1556C16.0243 9.1096 15.8126 7.96502 16.2132 6.81329L14.3263 5.72388C13.5321 6.64266 12.4371 7.03577 11.3874 7.31735ZM3.70153 15.8699C3.28732 16.5874 3.53313 17.5048 4.25057 17.919C4.70178 18.1795 5.23218 18.1797 5.66903 17.963C5.89398 17.8515 6.15983 17.8599 6.37728 17.9854C6.59473 18.1109 6.73493 18.337 6.75079 18.5876C6.78157 19.0742 7.04695 19.5335 7.49816 19.794C7.94938 20.0545 8.47977 20.0547 8.91663 19.838C9.14157 19.7265 9.40742 19.7349 9.62488 19.8604C9.84233 19.9859 9.98253 20.212 9.99838 20.4626C10.0292 20.9492 10.2945 21.4085 10.7458 21.669C11.4632 22.0832 12.3806 21.8374 12.7948 21.1199L13.5446 19.8212L4.45138 14.5712L3.70153 15.8699Z"
        fill="#0BA5EC"
      />
    </g>
    <defs>
      <clipPath id="clip0_14601_21335">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const RestSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      d="M18.525 11.2941C19.8413 11.2941 20.9176 12.4022 20.9955 13.8006L21 13.9632V20.2721C21 20.6741 20.6978 21 20.325 21C19.9833 21 19.7009 20.7261 19.6562 20.3708L19.65 20.2721V18.0882H4.35V20.2721C4.35 20.6406 4.09606 20.9452 3.76659 20.9934L3.675 21C3.33327 21 3.05086 20.7261 3.00616 20.3708L3 20.2721V13.9632C3 12.5437 4.02753 11.383 5.32423 11.299L5.475 11.2941H18.525ZM7.275 4.5H16.725C18.0413 4.5 19.1176 5.60812 19.1955 7.00652L19.2 7.16912V10.3235H16.5L16.4939 10.2103C16.446 9.76476 16.1181 9.41122 15.705 9.35947L15.6 9.35294H13.8C13.3384 9.35294 12.958 9.72763 12.9061 10.2103L12.9 10.3235H11.1L11.0939 10.2103C11.046 9.76476 10.7181 9.41122 10.305 9.35947L10.2 9.35294H8.4C7.93845 9.35294 7.55804 9.72763 7.50605 10.2103L7.5 10.3235H4.8V7.16912C4.8 5.7496 5.82753 4.5889 7.12423 4.50487L7.275 4.5Z"
      fill="#7A5AF8"
    />
  </svg>
);

const StressSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      d="M14.5 5C14.5 6.3807 13.3807 7.5 12 7.5C10.6193 7.5 9.5 6.3807 9.5 5C9.5 3.61929 10.6193 2.5 12 2.5C13.3807 2.5 14.5 3.61929 14.5 5Z"
      fill="#FF692E"
    />
    <path
      d="M15.5 21.5001C15.5 21.7762 15.2761 22.0001 15 22.0001H6.42795C5.6393 22.0001 5 21.3608 5 20.5721C5 20.0784 5.2551 19.6197 5.6745 19.3592L8.31645 17.7182C8.75705 17.4445 9.025 16.9627 9.025 16.444V16.0001H10.212C10.7424 16.0001 11.2511 15.7894 11.6262 15.4143L12 15.0405L12.3737 15.4143C12.7488 15.7894 13.2576 16.0001 13.788 16.0001H15V16.4458C15 16.963 15.2664 17.4437 15.705 17.7178L18.3278 19.3571C18.746 19.6184 19 20.0768 19 20.5699C19 21.3598 18.3597 22.0001 17.5698 22.0001H16.4146C16.4699 21.8437 16.5 21.6754 16.5 21.5001V21.3752C16.5 20.6237 15.9438 19.9881 15.1989 19.8884L8.5913 19.0045C8.3176 18.9679 8.06605 19.1601 8.0294 19.4338C7.9928 19.7075 8.185 19.959 8.4587 19.9957L15.0663 20.8796C15.3146 20.9128 15.5 21.1247 15.5 21.3752V21.5001Z"
      fill="#FF692E"
    />
    <path
      d="M13.0805 14.7071L12.4996 14.1263V12.255C12.5737 12.2978 12.6434 12.3508 12.7067 12.4142L13.6461 13.3535C13.7398 13.4473 13.867 13.5 13.9996 13.5H15.4996C15.6729 13.5 15.8338 13.4102 15.9249 13.2628C16.016 13.1154 16.0243 12.9314 15.9468 12.7764L15.4468 11.7764C15.3233 11.5294 15.023 11.4293 14.776 11.5528C14.529 11.6763 14.4289 11.9766 14.5524 12.2236L14.6906 12.5H14.2067L13.4138 11.7071C12.6328 10.926 11.3664 10.926 10.5854 11.7071L9.79251 12.5H9.30861L9.44681 12.2236C9.57031 11.9766 9.47021 11.6763 9.22321 11.5528C8.97621 11.4293 8.67586 11.5294 8.55241 11.7764L8.05241 12.7764C7.97491 12.9314 7.98316 13.1154 8.07426 13.2628C8.16536 13.4102 8.32631 13.5 8.49961 13.5H9.99961C10.1322 13.5 10.2594 13.4473 10.3532 13.3535L11.2925 12.4142C11.3559 12.3508 11.4256 12.2978 11.4996 12.255V14.1263L10.9187 14.7071C10.7312 14.8946 10.4768 15 10.2116 15H7.19236C6.48901 15 6.00541 14.2931 6.26036 13.6376L7.76241 9.7751C8.06136 9.00635 8.80161 8.5 9.62641 8.5H14.3728C15.1976 8.5 15.9378 9.00635 16.2368 9.7751L17.7389 13.6376C17.9938 14.2931 17.5102 15 16.8068 15H13.7876C13.5224 15 13.268 14.8946 13.0805 14.7071Z"
      fill="#FF692E"
    />
  </svg>
);

const StartExerciseSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      d="M15.625 4C15.625 5.10455 14.7296 6 13.625 6C12.5204 6 11.625 5.10455 11.625 4C11.625 2.89543 12.5204 2 13.625 2C14.7296 2 15.625 2.89543 15.625 4Z"
      fill="#66C61C"
    />
    <path
      d="M12.8466 14.1985L15.4431 16.7604C15.5427 16.8587 15.6205 16.9767 15.6716 17.1069L17.0562 20.6347C17.258 21.1488 17.0048 21.7292 16.4907 21.9309C15.9766 22.1327 15.3963 21.8795 15.1945 21.3654L13.8874 18.0351L9.42298 13.63C9.20458 13.4145 9.09718 13.1106 9.13168 12.8057L9.48928 9.64602C8.62733 10.5024 7.96243 11.7072 7.45288 13.3036C7.28498 13.8297 6.72233 14.1201 6.19618 13.9521C5.67003 13.7842 5.37968 13.2215 5.54763 12.6954C6.48273 9.76567 8.03528 7.57277 10.6401 6.56712L10.6519 6.56262C11.3153 6.31612 12.0055 6.33597 12.6201 6.65377C13.2109 6.95917 13.6314 7.48662 13.9068 8.07777C14.0227 8.32667 14.1299 8.55927 14.2306 8.77762C14.4745 9.30652 14.6799 9.75212 14.877 10.1437C15.1539 10.6939 15.3764 11.0581 15.5963 11.3143C15.8001 11.5518 16.003 11.6977 16.2615 11.7986C16.5393 11.9071 16.9288 11.9819 17.5302 11.9999C18.0823 12.0165 18.5164 12.4774 18.4998 13.0295C18.4832 13.5815 18.0223 14.0156 17.4703 13.999C16.7478 13.9774 16.1063 13.8851 15.5339 13.6616C14.9421 13.4305 14.4755 13.0793 14.0787 12.617C13.8285 12.3255 13.609 11.993 13.4033 11.6311L12.8466 14.1985Z"
      fill="#66C61C"
    />
    <path
      d="M9.13134 15.1099L10.7889 16.6997L10.0262 19.2735C9.96714 19.4726 9.84759 19.6485 9.68404 19.7765L7.11674 21.7877C6.68199 22.1283 6.05344 22.0519 5.71284 21.6172C5.37224 21.1824 5.44859 20.5539 5.88339 20.2133L8.19934 18.399L8.89719 16.044L9.13134 15.1099Z"
      fill="#66C61C"
    />
  </svg>
);

const GoalCard = () => {
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [goalDetails, setGoalDetails] = useState([]);
  const [wellnessScore, setWellnessScore] = useState(0);
  const [day, setDay] = useState(null);
  const [isScoreVisible, setIsScoreVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const goal = localStorage.getItem('Goal');
        const goalDetailsString = localStorage.getItem('GoalDetails');
        const day = localStorage.getItem('Day');

        const parsedGoalDetails = goalDetailsString
          ? JSON.parse(goalDetailsString)
          : [];

        // Set the goal and details
        if (goal) {
          setSelectedGoal(goal);
          setGoalDetails(parsedGoalDetails);
          setDay(Number(day));
        }

        const encodedGoal = encodeURIComponent(goal);

        // Fetch wellness score from user database
        if (userId) {
          const userDbUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/get-wellness-score/${userId}`;

          try {
            const userDbResponse = await axios.get(userDbUrl);

            // Check if valid data is returned
            if (
              userDbResponse.data &&
              typeof userDbResponse.data.wellnessScore === 'number'
            ) {
              setWellnessScore(Math.round(userDbResponse.data.wellnessScore));
              return; // Exit if score is found
            }
          } catch (error) {
            console.error('Error fetching from user DB:', error);
          }

          // Fallback to calculate wellness score
          const fallbackUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/wellness-score/${userId}?goal=${encodedGoal}`;
          console.log('Fallback API call to:', fallbackUrl);

          try {
            const fallbackResponse = await axios.get(fallbackUrl);

            console.log('Fallback API Response:', fallbackResponse.data);

            if (
              fallbackResponse.data &&
              typeof fallbackResponse.data.wellnessScore === 'number'
            ) {
              setWellnessScore(Math.round(fallbackResponse.data.wellnessScore));
            } else {
              console.error(
                'Invalid fallback wellness score data:',
                fallbackResponse.data
              );
              setWellnessScore(0);
            }
          } catch (error) {
            console.error('Error fetching from fallback URL:', error);
            setWellnessScore(0);
          }
        }
      } catch (error) {
        console.error('Full error:', error);
        console.error('Error details:', {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          url: error.config?.url,
        });
        setWellnessScore(0);
      }
    };

    fetchData();
  }, []);

  let goalTitle = '';
  let goalOptions = [];
  let bottomItems = [];

  if (selectedGoal) {
    if (selectedGoal.includes('Control')) {
      goalTitle = 'Control';
      goalOptions = goalDetails;
      bottomItems = [
        {
          svg: <ExerciseSVG />,
          title: 'Exercise regularly',
          subtitle: '30-40 mins a day',
          color: '#66C61C',
        },
        {
          svg: <DietSVG />,
          title: 'Low sugar diet',
          subtitle: 'Avoid sugar in your food',
          color: '#EAAA08',
        },
        {
          svg: <HydratedSVG />,
          title: 'Stay hydrated',
          subtitle: 'Drink 3-4 litres of water daily',
          color: '#0BA5EC',
        },
      ];
    } else if (selectedGoal.includes('Recover')) {
      goalTitle = 'Recover from';
      goalOptions = goalDetails;
      bottomItems = [
        {
          svg: <StartExerciseSVG />,
          title: 'Start exercise',
          subtitle: 'Do light activity for 15-20 mins a day',
          color: '#66C61C',
        },
        {
          svg: <RestSVG />,
          title: 'Enough rest',
          subtitle: 'Aim 7-9 hrs of good sleep',
          color: '#7A5AF8',
        },
        {
          svg: <DietSVG />,
          title: 'Eat healthy',
          subtitle: 'Have a balanced diet',
          color: '#EAAA08',
        },
      ];
    } else if (selectedGoal.includes('Be healthy and live long')) {
      goalTitle = 'Improve energy throughout the day';
      goalOptions = [];
      bottomItems = [
        {
          svg: <RestSVG />,
          title: 'Sound sleep',
          subtitle: 'Aim for 7-9 hrs of good sleep',
          color: '#7A5AF8',
        },
        {
          svg: <StressSVG />,
          title: 'Manage stress',
          subtitle: 'Meditate for 10-15 mins a day',
          color: '#FF692E',
        },
        {
          svg: <DietSVG />,
          title: 'Nutritional diet',
          subtitle: 'Have Vit B12 & Magnesium',
          color: '#EAAA08',
        },
        {
          svg: <ExerciseSVG />,
          title: 'Exercise regularly',
          subtitle: '30-40 mins a day',
          color: '#66C61C',
        },
      ];
    } else {
      goalTitle = selectedGoal;
      goalOptions = goalDetails;
      bottomItems = [];
    }
  }

  const getScanMessageAndClass = () => {
    if (day >= 5) {
      return {
        message: 'Here is your comprehensive report',
        className: 'text-gray-500 ',
      };
    }
    return {
      message:
        'Scan everyday for 5 days. Unlock your heart wellness score and get personalized advice.',
      className: 'text-gray-500',
    };
  };

  return (
    <div className="flex flex-col rounded-2xl bg-gradient-to-b from-[#FECDD699] to-[#FEF6EE99]">
      {/* Goal header section - with padding */}
      <div className="p-4">
        <div className="flex justify-between mb-6">
          <div className="flex flex-col">
            <p className="text-sm text-gray-600 font-inter">Goal</p>
            <h2 className="text-xl font-semibold text-gray-800 max-w-[150px] font-inter">
              {selectedGoal
                ? selectedGoal.includes('Control')
                  ? 'Control'
                  : selectedGoal
                : 'Loading...'}
            </h2>
            {goalDetails.length > 0 && (
              <p className="text-base font-medium text-gray-800 mt-1 font-inter">
                {goalDetails[0]}
              </p>
            )}
          </div>

          <div className="relative">
            <ProgressGauge
              value={wellnessScore}
              extendAngleDegrees={45}
              isLocked={!isScoreVisible}
            />
          </div>
        </div>
      </div>

      {/* Scan section - without padding */}
      <div className="w-full px-4">
        {/* Test background */}
        <div className="px-0">
          <p
            className={`text-sm ${
              getScanMessageAndClass().className
            } font-inter`}
          >
            {getScanMessageAndClass().message}
          </p>
        </div>
        <div className="flex w-full mt-4">
          {[1, 2, 3, 4, 5].map((dotDay, index) => (
            <div
              key={dotDay}
              className={`flex items-center ${index < 4 ? 'flex-1' : ''}`}
            >
              {/* Circle */}
              <div
                className={`w-5 h-5 rounded-full flex items-center justify-center flex-shrink-0 ${
                  dotDay < (day || 1)
                    ? 'bg-[#32D583]'
                    : dotDay === (day || 1)
                    ? 'border-[6px] border-[#32D583] bg-white shadow-[0_0_0_4px_rgba(50,213,131,0.2)]'
                    : 'border-[1px] border-[#1F2A375C]'
                }`}
              >
                {dotDay < (day || 1) && (
                  <svg
                    className="w-3 h-3 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              {/* Horizontal Line */}
              {index < 4 && (
                <div
                  className={`h-[1px] flex-grow ${
                    dotDay < (day || 1) ? 'bg-[#32D583]' : 'bg-[#1F2A375C]'
                  }`}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Bottom section */}
      <hr className="my-4 border-gray-200 w-full px-4" />
      <div
        style={{
          scrollbarWidth: 'none', // hide scrollbar in firefox
          msOverflowStyle: 'none', // hides scrollbar in IE and Edge
        }}
      >
        <div className="flex overflow-x-auto space-x-4 scrollbar-hide w-full px-4 pb-4">
          {bottomItems.map((item, index) => (
            <div
              key={index}
              className="flex-shrink-0 flex flex-col items-start min-w-[80px]"
            >
              <div>{item.svg}</div>
              <p
                className="text-[12px] font-[500] leading-[14.4px] pt-1 text-left font-inter"
                style={{ color: item.color }}
              >
                {item.title}
              </p>
              <p className="text-xs text-gray-600 mt-1 max-w-[120px] font-inter">
                {item.subtitle}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GoalCard;
