import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../utils/Loader';
import JourneyLayout from '../../components/JourneyLayout';
import chevron from '../../assets/chevronLeft.svg';
import { Link } from 'react-router-dom';
import xclose from '../../assets/x-close.svg';
import GaugeIncrease from '../../assets/GaugeIncrease.svg';
import GaugeDecrease from '../../assets/GaugeDecrease.svg';
import GaugeNeutral from '../../assets/GaugeNeutral.svg';
import ProgressGauge from '../../components/ProgressGauge';
import { markActivityComplete } from '../../utils/activityUtils';
import GoalTick from '../../assets/GoalTick.png';
import GoalSnooze from '../../assets/GoalSnooze.png';
import GoalThumbsUp from '../../assets/GoalThumbsUp.png';

const mockQuestions = [
  {
    questionNo: 1,
    question: 'Did you workout today?',
    options: [
      'Yes I did for 30-40 mins',
      'Had a light workout (e.g., walking)',
      'No, I missed out on it',
    ],
    _id: '1',
  },
  {
    questionNo: 2,
    question: 'How was your sleep?',
    options: [
      'Slept well and rested',
      'Broken sleep',
      'Didn’t get proper sleep',
    ],
    _id: '2',
  },
  {
    questionNo: 3,
    question: 'Did you feel stressed or anxious today?',
    options: ['No, not at all', 'Slightly but manageable', 'Yes, a lot'],
    _id: '3',
  },
  {
    questionNo: 4,
    question: 'How was your water intake today?',
    options: [
      'High (3-4 litres)',
      'Moderate (1-2 litres)',
      'Very little (less than 1 litre)',
    ],
    _id: '4',
  },
  {
    questionNo: 5,
    question: 'How was your diet today?',
    options: [
      'Had a balanced diet',
      'Had a mix of both',
      'Did not eat anything healthy',
    ],
    _id: '5',
  },
  {
    questionNo: 6,
    question: 'Did you have sugar today?',
    options: ['No, not at all', 'Had a little bit', 'Yes, more than normal'],
    _id: '6',
  },
];

const scoringMap = {
  0: 1, // Positive
  1: 0, // Neutral
  2: -1, // Negative
};

const ActivityQuestionnaire = ({ onSubmit }) => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showTransition, setShowTransition] = useState(false);
  const [transitionData, setTransitionData] = useState(null);
  const [score, setScore] = useState(null);

  const userId = localStorage.getItem('userId');

  // Set activity questions
  useEffect(() => {
    setQuestions(mockQuestions || []);
  }, []);

  useEffect(() => {
    const savedAnswers = localStorage.getItem('questionnaireAnswers');
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('questionnaireAnswers', JSON.stringify(answers));
  }, [answers]);

  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswer = (answer, optionIndex) => {
    setSelectedOption(optionIndex);
    setAnswers({
      ...answers,
      [currentQuestionIndex]: { answer, answerIndex: optionIndex },
    });

    setTimeout(() => {
      nextQuestion();
    }, 400);
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setSelectedOption(null);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      submitAnswers();
    }
  };

  const submitAnswers = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      console.log('Submitting answers:', answers);

      // Calculate the total score from answers
      let score = 0;
      let positiveCount = 0;
      let neutralCount = 0;
      let negativeCount = 0;

      Object.values(answers).forEach(({ answerIndex }) => {
        // Safeguard to ensure answerIndex is defined and valid
        if (typeof answerIndex === 'number' && answerIndex in scoringMap) {
          const mappedScore = scoringMap[answerIndex];
          score += mappedScore;

          // Count the types of responses
          if (mappedScore === 1) positiveCount++;
          else if (mappedScore === 0) neutralCount++;
          else if (mappedScore === -1) negativeCount++;
        } else {
          console.warn('Invalid answerIndex detected:', answerIndex);
        }
      });
      console.log('Total calculated score:', score);

      // Determine adjustment based on majority
      const totalResponses = positiveCount + neutralCount + negativeCount;
      const adjustment =
        positiveCount > negativeCount && positiveCount > neutralCount
          ? 1
          : negativeCount > positiveCount && negativeCount > neutralCount
          ? -1
          : 0;

      console.log('Score adjustment:', adjustment);
      setTransitionData(adjustment);

      // Fetch current wellness score
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/get-wellness-score/${userId}`
      );
      const currentWellnessScore = response.data?.wellnessScore;
      setScore(currentWellnessScore + adjustment);
      console.log('Current wellness score:', currentWellnessScore);

      if (typeof currentWellnessScore !== 'number') {
        throw new Error('Invalid wellness score received from server');
      }

      // Update wellness score
      const newWellnessScore = currentWellnessScore + adjustment;

      console.log('Updating wellness score to:', newWellnessScore);

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/wellness-score/${userId}`,
        {
          wellnessScore: newWellnessScore,
        }
      );

      markActivityComplete();
      setShowTransition(true);
      setTimeout(() => {
        navigate('/home');
      }, 4000);
    } catch (error) {
      console.error(
        'Error submitting answers:',
        error.response?.data || error.message
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderTransitionScreen = () => {
    if (transitionData > 0) {
      return (
        <div
          style={{
            background:
              'radial-gradient(164.49% 96.13% at 50.13% 50%, #FFF1F3 16.53%, #FFE4E8 61.36%, #FDEAD7 100%)',
            height: '100vh',
          }}
        >
          <div className="flex flex-col justify-center items-center h-full w-full">
            <div className="relative flex justify-center items-center">
              <img
                src={GaugeIncrease}
                alt="positive graph"
                className="relative"
              ></img>
              <div className="absolute">
                <img
                  src={GoalTick}
                  alt="tick mark"
                  className="h-[125px] w-[125px]"
                ></img>
              </div>
            </div>

            <h1 className="text-light_bg_primary display-title-medium">
              Great job!
            </h1>
            <p className="text-md-regular text-light_bg_secondary text-center mx-12 mt-2">
              You’re closer to achieving your goal.
            </p>
          </div>
        </div>
      );
    } else if (transitionData < 0) {
      return (
        <div
          style={{
            background:
              'radial-gradient(164.49% 96.13% at 50.13% 50%, #FFF1F3 16.53%, #FFE4E8 61.36%, #FDEAD7 100%)',
            height: '100vh',
          }}
        >
          <div className="flex flex-col justify-center items-center h-full w-full">
            <div className="relative flex justify-center items-center">
              <img
                src={GaugeDecrease}
                alt="positive graph"
                className="relative"
              ></img>
              <div className="absolute">
                <img
                  src={GoalSnooze}
                  alt="tick mark"
                  className="h-[125px] w-[125px]"
                ></img>
              </div>
            </div>
            <h1 className="text-light_bg_primary display-title-medium">
              Don’t worry
            </h1>
            <p className="text-md-regular text-light_bg_secondary text-center mx-12 mt-2">
              Don't lose focus and take a small step tomorrow
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            background:
              'radial-gradient(164.49% 96.13% at 50.13% 50%, #FFF1F3 16.53%, #FFE4E8 61.36%, #FDEAD7 100%)',
            height: '100vh',
          }}
        >
          <div className="flex flex-col justify-center items-center h-full w-full">
            <div className="relative flex justify-center items-center">
              <img
                src={GaugeNeutral}
                alt="positive graph"
                className="relative"
              ></img>
              <div className="absolute">
                <img
                  src={GoalThumbsUp}
                  alt="thumbs up"
                  className="h-[125px] w-[125px]"
                ></img>
              </div>
            </div>
            <h1 className="text-light_bg_primary display-title-medium">
              No worries!
            </h1>
            <p className="text-md-regular text-light_bg_secondary text-center mx-12 mt-2">
              Even if you were not able to do much today, you can add your
              activity tomorrow!
            </p>
          </div>
        </div>
      );
    }
  };

  if (isSubmitting) {
    return <Loader />;
  }

  if (showTransition) {
    return renderTransitionScreen();
  }

  const handleBack = () => {
    if (currentQuestionIndex === 0) {
      navigate('/home');
    } else {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <>
      <JourneyLayout>
        <div className="relative flex items-center mt-4 mb-5">
          <div
            className="absolute left-0 flex items-center justify-center h-[32px] w-[32px] bg-[#F3F4F6] rounded-full cursor-pointer"
            onClick={handleBack}
          >
            <img
              src={chevron}
              alt="back"
              className="max-w-[18px] max-h-[18px]"
            />
          </div>

          <h2 className="heading-large text-center text-light_bg_primary opacity-95 m-auto">
            How was your day?
          </h2>
          <Link
            to="/home"
            className="absolute right-0 flex items-center justify-center h-[32px] w-[32px] bg-[#F3F4F6] rounded-full cursor-pointer"
          >
            <div>
              <img
                src={xclose}
                alt="Close"
                className="max-w-[18px] max-h-[18px]"
              />
            </div>
          </Link>
        </div>

        <div className="max-w-md mx-auto">
          <div className="mb-4">
            <div className="flex space-x-1">
              {questions.map((_, index) => (
                <div
                  key={index}
                  className={`h-1 w-full rounded-[4px] ${
                    index < currentQuestionIndex
                      ? 'bg-primary_500'
                      : 'bg-[#E5E7EB]'
                  }`}
                />
              ))}
            </div>
          </div>
          <p className="text-sm-regular text-light_bg_secondary mt-6">
            Share with us the activities you did towards your health goal
          </p>
          <div className="mt-16 shadow-question_card bg-gray_neutral_50 pt-5 px-5 pb-32 rounded-[16px]">
            {currentQuestion && (
              <>
                <h3 className="text-light_bg_primary text-md-regular mb-6">
                  {currentQuestion.question}
                </h3>
                {currentQuestion.options.map((option, index) => (
                  <button
                    key={index}
                    className={`w-full text-md-regular text-gray_600 text-left p-3 mb-4 bg-white rounded-[8px] ${
                      selectedOption === index
                        ? 'border-secondary_500 border-[1px] shadow-user_details_input'
                        : 'border-[#E5E7EB] border-[1px]'
                    }`}
                    onClick={() => handleAnswer(option, index)}
                  >
                    {option}
                  </button>
                ))}
              </>
            )}
          </div>
        </div>
      </JourneyLayout>
    </>
  );
};

export default ActivityQuestionnaire;
