import React from 'react';
import xclose from '../../assets/x-close.svg';
import DownArrowRed from '../../assets/DownArrowRed.svg';
import ListRedDisc from '../../assets/ListRedDisc.png';
import Tickbw from '../../assets/Tickbw.svg';

const HeartEnergyDetailModal = ({ isOpen, onClose }) => {
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
        isOpen ? 'visible' : 'invisible'
      }`}
      onClick={handleBackdropClick}
    >
      <div
        className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[75%] overflow-y-auto ${
          isOpen ? 'translate-y-0' : 'translate-y-full'
        }`}
      >
        <div className="p-5">
          <div className="flex justify-between items-center mb-[2px]">
            <h2 className="text-[28px] font-inter font-normal leading-[33.6px] tracking-[-0.28px] text-light_bg_primary">
              Heart energy
            </h2>
            <button
              onClick={onClose}
              className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
            >
              <img src={xclose} alt="Close" />
            </button>
          </div>

          <p className="text-light_bg_secondary body-large font-inter mt-5">
            It is an optimal measure of how your heart health aligns with the
            recommended standards for your age and gender
          </p>

          <h3 className="text-light_bg_primary heading-large mt-10 mb-2">
            Factors affecting this
          </h3>
          <div className="bg-[#F9FAFB] p-4 rounded-[12px] flex-col flex gap-y-1">
            <div className="flex items-center">
              <img src={Tickbw} alt="Tick" className="mr-2" />
              <p className="font-inter text-light_bg_primary opacity-85">
                Number of heart beats per minute
              </p>
            </div>
            <div className="flex items-center">
              <img src={Tickbw} alt="Tick" className="mr-2" />
              <p className="font-inter text-light_bg_primary opacity-85">
                Fluctuations in your number of heart beats per minute
              </p>
            </div>
          </div>

          <h3 className="text-light_bg_primary heading-large mt-10 mb-2">
            What does it indicate?
          </h3>
          <div className="p-4 bg-[#FEF3F2] rounded-[16px]">
            <div className="flex items-center">
              <img src={DownArrowRed} alt="Down Arrow" />
              <h4 className="heading-small ml-1 text-[#F04438]">
                If your heart energy is low, you'll feel
              </h4>
            </div>
            <div className="mt-4 flex flex-col gap-y-2">
              <div className="flex items-center">
                <img
                  src={ListRedDisc}
                  alt="List Icon"
                  className="w-3 h-3 mr-2"
                />
                <p className="text-light_bg_primary opacity-85">Dizziness</p>
              </div>
              <div className="flex items-center">
                <img
                  src={ListRedDisc}
                  alt="List Icon"
                  className="w-3 h-3 mr-2"
                />
                <p className="text-light_bg_primary opacity-85">
                  Shortness of breath
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={ListRedDisc}
                  alt="List Icon"
                  className="w-3 h-3 mr-2"
                />
                <p className="text-light_bg_primary opacity-85">Tiredness</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeartEnergyDetailModal;
