import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MetricDetailLayout from './MetricDetailLayout';
import HeartAgeDetailModal from '../../components/Modals/HeartAgeDetailModal';

const HeartAgeDetail = () => {
  const location = useLocation();
  const { value } = location.state?.data || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Get user's date of birth and calculate actual age
  const userData = JSON.parse(localStorage.getItem('userData'));
  const dob = userData?.dob;

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }
    return age;
  };

  const actualAge = dob ? calculateAge(dob) : null;
  const isHigher = actualAge ? value > actualAge : true;

  const metricInfo = {
    title: 'Heart age',
    subtitle:
      'Estimated age of your heart based on the risk factors for cardiovascular disease',
    value: value,
    unit: 'Years',
    normalRange:
      "Your heart age is older than your actual age. It's nothing to panic about, but you might be",
    rangeDescription: 'prone to cardiovascular risk.',
    isSuccess: !isHigher,
    advice:
      'Workout with moderate intensity for 150 minutes/week. While you do so, your activity heart rate between 116-138 beats per minute would be a safe zone.',
    symptoms: [
      'Your risk of a heart attack or stroke may be higher if your heart age is greater than your actual age.',
    ],
    learnMoreText: 'Learn more about Heart age',
    onLearnMoreClick: () => setIsModalOpen(true),
  };

  return (
    <>
      <MetricDetailLayout metricInfo={metricInfo} />
      <HeartAgeDetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        heartRate={value}
      />
    </>
  );
};

export default HeartAgeDetail;
