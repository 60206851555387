import React, { useState } from 'react';
import MetricDetailLayout from './MetricDetailLayout';
import { useLocation } from 'react-router-dom';
import { getAdvice } from '../../utils/adviceLogic';
import SleepApneaRiskDetailModal from '../../components/Modals/SleepApneaRiskDetailModal';

const SleepApneaDetail = () => {
  const location = useLocation();
  const { value } = location.state?.data || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const metricInfo = {
    title: 'Sleep apnea risk',
    subtitle:
      'A screening tool used to determine your risk of developing sleep-related breathing issues',
    value: value <= 5 ? 'Low' : 'High',
    unit: '',
    normalRange: 'Lower scores indicate a lower risk of sleep apnea.',
    rangeDescription:
      value <= 5
        ? 'Yours lie on the lower end of it.'
        : 'Your score indicates an increased risk of sleep apnea.',
    isSuccess: value <= 5,
    advice: getAdvice('Sleep apnea risk', value),
    symptoms: [
      'Throat muscles might relax during sleep and block air passage.',
      'Blocked breathing can cause snoring',
    ],
    learnMoreText: 'Learn more about Sleep apnea',
    onLearnMoreClick: () => setIsModalOpen(true),
  };

  return (
    <>
      <MetricDetailLayout metricInfo={metricInfo} />
      <SleepApneaRiskDetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        heartRate={value}
      />
    </>
  );
};

export default SleepApneaDetail;
