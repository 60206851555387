import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HeartCheckPalm from '../../assets/HeartCheckPalm.svg';
import Layout from '../../components/Layout';
import CtaButton from '../../components/CtaButton';
import Loader from '../../utils/Loader';
import PreOnboarding from '../../assets/PreOnboarding.svg';

const UserDetailsPreview = () => {
  const [userData, setUserData] = useState({
    firstname: '',
    dob: '',
    gender: '',
    height: '',
    weight: '',
    goal: '',
    goalDetails: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = localStorage.getItem('userId'); // Fetch userId from localStorage
      if (userId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}`
          );
          setUserData(response.data);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setIsLoading(false);
        }
      } else {
        console.error('No userId found in localStorage');
      }
    };

    fetchUserData();
  }, []);

  const calculateAge = (dob) => {
    const birthDate = new Date(dob.split('/').reverse().join('-'));
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs); // Epoch time
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${PreOnboarding})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Layout>
        <div className="mt-[46px]">
          <img src={HeartCheckPalm} alt="heartcheck"></img>
          <h1 className="display-title text-light_bg_primary mt-4">
            Welcome to <span className="text-[#F63D68]">Heartcheck</span>{' '}
            {userData.firstname},
          </h1>
          <p className="mt-2 text-sm-regular text-light_bg_secondary">
            You’ve taken the first step on your health journey. Come back
            everyday and make progress towards your goal
          </p>
        </div>

        {/* goal card */}
        <div
          className="p-4 border-[1px] mt-[24px] border-[#1F2A371F] rounded-[16px]"
          style={{
            background:
              'linear-gradient(254deg, #FECDD6 31.44%, #F8B98D 75.23%, #F38744 119.01%)',
          }}
        >
          <p className="text-sm-medium text-light_bg_secondary">Goal</p>
          <p className="text-light_bg_primary heading-large mt-1">
            {userData.goal}
          </p>
          <p className="text-light_bg_primary text-[14px] font-inter font-normal leading-[21px]">
            {userData.goalDetails}
          </p>
        </div>

        {/* Bio Card */}
        <div
          style={{
            backgroundImage: 'linear-gradient(to bottom, #FECDD699, #FEF6EE99)',
          }}
          className="p-4 rounded-[16px] border-[1px] mt-4 border-[#1F2A371F]"
        >
          <p className="text-sm-medium text-light_bg_secondary mb-4">
            Your details
          </p>
          <div className="grid grid-cols-2 gap-y-4">
            {/* Age */}
            <div>
              <p className="text-sm-regular text-light_bg_secondary">Age</p>
              <p className="heading-large text-light_bg_primary">
                {userData.dob ? calculateAge(userData.dob) : 'N/A'}
              </p>
            </div>
            {/* Gender */}
            <div>
              <p className="text-sm-regular text-light_bg_secondary">Gender</p>
              <p className="heading-large text-light_bg_primary">
                {userData.gender}
              </p>
            </div>
            {/* Height */}
            <div>
              <p className="text-sm-regular text-light_bg_secondary">Height</p>
              <p className="heading-large text-light_bg_primary">
                {userData.height} cm
              </p>
            </div>
            {/* Weight */}
            <div>
              <p className="text-sm-regular text-light_bg_secondary">Weight</p>
              <p className="heading-large text-light_bg_primary">
                {userData.weight} kg
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-sm-regular text-center mb-2 text-light_bg_secondary">
            Let’s help you achieve this goal
          </p>
          <CtaButton
            ButtonText="Continue"
            LinkTo="/intro-questionnaire/questions"
          />
        </div>
      </Layout>
    </div>
  );
};

export default UserDetailsPreview;
