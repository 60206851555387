import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import LockNew from '../assets/locknew.svg';

const AnimatedProgressGauge = ({
  value,
  extendAngleDegrees = 45,
  color = '#F63D68',
  isLocked = false,
}) => {
  const ref = useRef();
  const prevValueRef = useRef(0);

  useEffect(() => {
    const width = 111;
    const thickness = 6;
    const extendAngle = (extendAngleDegrees * Math.PI) / 180;
    const startAngle = -Math.PI / 2 - extendAngle;
    const endAngle = Math.PI / 2 + extendAngle;
    const totalAngle = endAngle - startAngle;
    const radius = width / 2 - thickness;
    const height = radius + thickness + 50;

    d3.select(ref.current).selectAll('*').remove();

    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${radius + thickness + 10})`);

    // Background arc (full gauge)
    const backgroundArc = d3
      .arc()
      .innerRadius(radius)
      .outerRadius(radius + thickness)
      .startAngle(startAngle)
      .endAngle(endAngle)
      .cornerRadius(thickness / 2);

    svg.append('path').attr('d', backgroundArc).attr('fill', '#1F2A371F');

    if (!isLocked) {
      const roundedValue = Math.round(value);
      const roundedPrevValue = Math.round(prevValueRef.current);

      const progressArc = d3
        .arc()
        .innerRadius(radius)
        .outerRadius(radius + thickness)
        .startAngle(startAngle)
        .cornerRadius(thickness / 2);

      const progressPath = svg
        .append('path')
        .attr('fill', color)
        .attr(
          'd',
          progressArc({
            endAngle: startAngle + (roundedPrevValue / 100) * totalAngle,
          })
        );

      // Animate progress arc
      progressPath
        .transition()
        .duration(750)
        .ease(d3.easeCircleOut)
        .attrTween('d', () => {
          const targetEndAngle = startAngle + (roundedValue / 100) * totalAngle;
          const angleInterpolate = d3.interpolate(
            startAngle + (roundedPrevValue / 100) * totalAngle,
            targetEndAngle
          );
          return (t) =>
            progressArc({
              startAngle: startAngle,
              endAngle: angleInterpolate(t),
            });
        });

      // Progress end point (circular marker)
      const endPoint = svg.append('circle').attr('r', 7).attr('fill', color);

      // Animate end point
      endPoint
        .transition()
        .duration(750)
        .ease(d3.easeCircleOut)
        .attrTween('cx', () => {
          return (t) => {
            const angle = d3.interpolate(
              startAngle + (roundedPrevValue / 100) * totalAngle,
              startAngle + (roundedValue / 100) * totalAngle
            )(t);
            return (radius + thickness / 2) * Math.cos(angle - Math.PI / 2);
          };
        })
        .attrTween('cy', () => {
          return (t) => {
            const angle = d3.interpolate(
              startAngle + (roundedPrevValue / 100) * totalAngle,
              startAngle + (roundedValue / 100) * totalAngle
            )(t);
            return (radius + thickness / 2) * Math.sin(angle - Math.PI / 2);
          };
        });

      // Value text
      const textElement = svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '5')
        .attr('class', 'text-light_bg_primary');

      const interpolateNumber = d3.interpolateNumber(
        roundedPrevValue,
        roundedValue
      );
      textElement
        .transition()
        .duration(750)
        .ease(d3.easeCircleOut)
        .tween('text', function () {
          return function (t) {
            const value = Math.round(interpolateNumber(t));
            this.innerHTML = `<tspan class="display-number-medium">${value}</tspan><tspan class="display-number">/100</tspan>`;
          };
        });

      prevValueRef.current = roundedValue;
    } else {
      // Center group for both lock and text
      const centerGroup = svg.append('g').attr('transform', 'translate(0, 0)');

      // Lock icon - centered at the middle
      centerGroup
        .append('image')
        .attr('xlink:href', LockNew)
        .attr('x', -24) // Shift left by half of the total width (57/2)
        .attr('y', -18) // Center vertically (28/2)
        .attr('width', 26)
        .attr('height', 26);

      // Add /100 text - centered along with lock
      centerGroup
        .append('text')
        .attr('text-anchor', 'start')
        .attr('x', 6) // Position right after lock with proper spacing
        .attr('y', 4) // Align vertically with lock
        .attr('class', 'text-gray-400')
        .style('font-size', '16px')
        .style('font-family', 'Inter')
        .text('/100');
    }
  }, [value, extendAngleDegrees, color, isLocked]);

  return <svg ref={ref}></svg>;
};

export default AnimatedProgressGauge;
