export const getAdvice = (metric, value) => {
  switch (metric) {
    case 'Resting heart rate':
      if (value < 56) {
        return 'Your heart rate is lower than normal. You should seek immediate medical attention if you experience fainting, dizziness, or shortness of breath.';
      } else if (value > 80) {
        return 'Your heart rate is higher than normal. You should seek immediate medical attention if you experience fainting, dizziness, or shortness of breath.';
      } else {
        return 'Congrats! Your heart rate is normal. You have a healthy heart! Keep it up and stay fit and healthy by exercising regularly.';
      }
    case 'Cardiorespiratory rhythm':
      if (value < 3) {
        return 'Your PRQ or pulse-to-breathing (CRR) rate ratio is very low. You should seek immediate medical attention and focus on your breathing, exercise daily, and manage stress levels.';
      } else if (value > 6) {
        return 'Your PRQ or pulse-to-breathing (CRR) rate ratio is very high. You should seek immediate medical attention and focus on your breathing, exercise daily, and manage stress levels.';
      } else {
        return 'Congrats! Your PRQ or pulse-to-breathing (CRR) rate ratio is normal. Keep maintaining a healthy lifestyle.';
      }
    case 'Diabetes risk score':
      if (value > 66) {
        return 'Your diabetes risk score is higher than normal. You should check your blood glucose regularly and control the level by living a healthy lifestyle.';
      } else if (value > 33) {
        return 'You are at a moderate risk of developing diabetes. Check your blood glucose regularly and live a healthy lifestyle.';
      } else {
        return 'Congrats! You are at a low risk of developing diabetes. Continue to lead a healthy lifestyle.';
      }
    case 'Sleep apnea risk':
      if (value > 5) {
        return 'Your OSA score is higher than normal. You are at a higher risk of obstructive sleep apnea. It is recommended to get checked for OSA and improve your lifestyle.';
      } else {
        return 'Congrats! Your risk of obstructive sleep apnea is low. Keep leading a healthy lifestyle.';
      }
    default:
      return 'Workout with moderate intensity for 150 minutes/week.';
  }
};
