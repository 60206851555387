import React from 'react';
import { useNavigate } from 'react-router-dom';

const ActivityCard = () => {
  const Navigate = useNavigate();
  const activityHandler = () => {
    Navigate('/activity-questionnaire');
  };
  return (
    <div
      className="p-4 rounded-2xl border-[1px] border-[#1F2A371F]"
      style={{
        background:
          'linear-gradient(254deg, #FECDD6 31.44%, #F8B98D 75.23%, #F38744 119.01%)',
      }}
    >
      <h1 className="text-light_bg_primary heading-large">How was your day?</h1>
      <p className="text-light_bg_primary text-[14px] font-inter leading-[21px] mt-1">
        Share with us the activities you did towards your health goal
      </p>
      <div
        onClick={activityHandler}
        className="mt-4 rounded-[8px] heading-small text-center text-base_white py-3 px-4 bg-[#111927]"
      >
        Add activities
      </div>
    </div>
  );
};

export default ActivityCard;
