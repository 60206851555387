import React, { useEffect, useState } from 'react';
import HeartRate from './HeartRate';
import RespirationRate from './RespirationRate';
import OxygenSaturation from './OxygenSaturation';
import axios from 'axios';
import ComponentLoader from '../utils/ComponentLoader';
import { sendAmplitudeData } from '../utils/amplitude';
import { Link } from 'react-router-dom';

const RecentScanCard = ({ showSeeAllButton = true }) => {
  const [lastScanResult, setLastScanResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchScanDetails = async () => {
      if (!userId) {
        setError('User ID not found');
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.data && response.data.length > 0) {
          const latestScan = response.data[response.data.length - 1];
          setLastScanResult(latestScan);
        } else {
          setError('No scan results found');
        }
      } catch (error) {
        console.error('Error fetching scan details:', error);
        setError('Failed to fetch scan details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchScanDetails();
  }, [userId]);

  const handleHeartRateClick = () => {
    sendAmplitudeData('TAP_HEART_RATE_CARD');
  };

  const handleRespirationRateClick = () => {
    sendAmplitudeData('TAP_RESPIRATION_RATE_CARD');
  };

  const handleOxygenSaturationClick = () => {
    sendAmplitudeData('TAP_OXYGEN_SATURATION_CARD');
  };

  if (isLoading) {
    return (
      <div>
        <ComponentLoader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="heading-large text-light_bg_primary">Recent scans</h1>
        {showSeeAllButton && (
          <Link to="/results">
            <p className="text-[#F63D68] text-sm-regular underline">See all</p>
          </Link>
        )}
      </div>

      <div className="mt-[14px]">
        {lastScanResult && (
          <>
            <div onClick={handleHeartRateClick}>
              <HeartRate
                heartRate={lastScanResult.vitals.heart_rate}
                label="Heart rate"
              />
            </div>

            <div className="flex justify-evenly items-stretch w-full gap-3">
              <div
                className="flex-1 min-w-0"
                onClick={handleRespirationRateClick}
              >
                <RespirationRate
                  respirationRate={lastScanResult.vitals.resp_rate}
                />
              </div>
              <div
                className="flex-1 min-w-0"
                onClick={handleOxygenSaturationClick}
              >
                <OxygenSaturation
                  oxygenSaturation={lastScanResult.vitals.oxy_sat_prcnt}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RecentScanCard;
