import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import HeartRate from '../../components/HeartRate';
import RespirationRate from '../../components/RespirationRate';
import OxygenSaturation from '../../components/OxygenSaturation';
import Vitalfirstcta from '../../assets/Vitalfirstcta.png';
import axios from 'axios';
import LearnMoreModal from '../../components/LearnMoreModal';
import { Link } from 'react-router-dom';
import Loader from '../../utils/Loader';
import { sendAmplitudeData } from '../../utils/amplitude';
import LockedGoalCard from '../../components/LockedGoalCard';

const VitalResultsFirst = () => {
  const [lastScanResult, setLastScanResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userId = localStorage.getItem('userId');

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    const fetchScanDetails = async () => {
      if (!userId) {
        setError('User ID not found');
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/scans/${userId}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.data && response.data.length > 0) {
          const latestScan = response.data[response.data.length - 1];
          console.log('Latest scan:', latestScan);
          setLastScanResult(latestScan);
        } else {
          setError('No scan results found');
        }
      } catch (error) {
        console.error('Error fetching scan details:', error);
        setError('Failed to fetch scan details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchScanDetails();
  }, [userId]);

  const handleHeartRateClick = () => {
    sendAmplitudeData('TAP_HEART_RATE_CARD');
  };

  const handleRespirationRateClick = () => {
    sendAmplitudeData('TAP_RESPIRATION_RATE_CARD');
  };

  const handleOxygenSaturationClick = () => {
    sendAmplitudeData('TAP_OXYGEN_SATURATION_CARD');
  };

  const handleCreateAccountClick = () => {
    sendAmplitudeData('TAP_CREATE_ACCOUNT');
  };

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div
      className="relative bg-gray_neutral_50"
      style={{
        backgroundImage: `url(${Vitalfirstcta})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <Layout>
        <div className="relative z-10">
          <h1 className="display-title mt-3 text-light_bg_primary">
            Your first scan
          </h1>
          <p className="text-sm-regular mt-2 text-light_bg_secondary mb-4">
            These are your body's vital signs from the finger scan. To
            understand them better{' '}
            <span className="text-primary_500 underline font-inter text-[14px] font-[500] leading-5">
              <button onClick={handleOpenModal}>learn more</button>
            </span>
          </p>
          {lastScanResult && (
            <>
              <div onClick={handleHeartRateClick}>
                <HeartRate
                  heartRate={lastScanResult.vitals.heart_rate}
                  label="Heart rate"
                />
              </div>

              <div className="flex justify-evenly items-stretch w-full gap-3">
                <div
                  className="flex-1 min-w-0"
                  onClick={handleRespirationRateClick}
                >
                  <RespirationRate
                    respirationRate={lastScanResult.vitals.resp_rate}
                  />
                </div>
                <div
                  className="flex-1 min-w-0"
                  onClick={handleOxygenSaturationClick}
                >
                  <OxygenSaturation
                    oxygenSaturation={lastScanResult.vitals.oxy_sat_prcnt}
                  />
                </div>
              </div>
            </>
          )}
          <div>
            <LockedGoalCard />
          </div>
        </div>
        <div className="heading-large text-light_bg_primary my-6 mb-8">
          <p>
            We need to know a few more things about your health to give you an
            accurate score
          </p>
          <p className="text-md-regular mt-4">
            Scan daily, 2 minutes a day. Start your heart health journey today.
          </p>
        </div>
      </Layout>

      <div className="px-4">
        <Link to="/create-account">
          <button
            className="bg-primary_500 heading-small w-full max-w-[361px] text-[#FCFCFD] py-3 px-5 rounded-[8px] mb-6"
            onClick={handleCreateAccountClick}
          >
            Get started
          </button>
        </Link>
      </div>

      {/* Learn More Modal */}
      <LearnMoreModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default VitalResultsFirst;
