// MetricDetailLayout.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import HandOpenHeart from '../../assets/HandOpenHeart.svg';
import CtaButton from '../../components/CtaButton';

const MetricDetailLayout = ({ metricInfo }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/results');
  };

  return (
    <Layout>
      <div className="flex items-center relative">
        <button
          onClick={handleBack}
          className="absolute left-0 p-2 bg-gray-100 rounded-full"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 18L9 12L15 6"
              stroke="#1F2A37"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <p className="text-light_bg_secondary heading-large my-5 mx-auto font-inter text-xs leading-5 font-normal">
          {metricInfo.title}
        </p>
      </div>
      <p className="body-large text-light_bg_secondary mt-2">
        {metricInfo.subtitle}
      </p>
      <div className="bg-[#F9FAFB] rounded-[16px]">
        <div className="flex pt-3 px-4 mt-2 items-baseline gap-x-[6px]">
          <h3 className="ph-display-number text-light_bg_primary">
            {metricInfo.value}
          </h3>
          <p className="body-large text-gray_400">{metricInfo.unit}</p>
        </div>

        <p className="text-light_bg_secondary font-inter font-normal leading-[22.4px] pb-3 px-4 mt-[4px]">
          {metricInfo.normalRange}{' '}
          {metricInfo.rangeDescription && (
            <span
              className={
                metricInfo.isSuccess ? 'text-success_500' : 'text-[#F79009]'
              }
            >
              {metricInfo.rangeDescription}
            </span>
          )}
        </p>
      </div>

      <div className="my-10">
        <div className="bg-[#0BA5EC] rounded-[16px] py-3 px-4 mt-2">
          <div className="flex items-center">
            <img src={HandOpenHeart} alt="Heart" />
            <h4 className="ml-2 text-[#F5FBFF] text-[20px] font-[500] font-inter leading-[24px] tracking-[-0.2px]">
              Our advice
            </h4>
          </div>
          <p className="text-[#F5FBFF] text-[16px] font-inter font-normal leading-[24px] mt-2">
            {metricInfo.advice}
          </p>
        </div>
      </div>

      <div className="px-2">
        <p className="text-light_bg_primary heading-large mb-2">
          Symptom information
        </p>
        <div className="bg-[#F9FAFB] p-4 rounded-[16px]">
          <ul className="list-disc text-light_bg_secondary pl-5 font-inter text-[16px] leading-[24px]">
            {metricInfo.symptoms.map((symptom, index) => (
              <li key={index} className="mb-2">
                {symptom}
              </li>
            ))}
          </ul>
        </div>
        <button
          className="body-large text-primary_500 underline mt-10 relative z-20 pb-20"
          onClick={metricInfo.onLearnMoreClick}
        >
          {metricInfo.learnMoreText}
        </button>
      </div>

      {/* <div className="w-full max-w-[393px] pt-10 mx-auto fixed bottom-0 left-0 right-0 px-5 pb-5 mt-4 z-10">
        <div onClick={handleBack}>
          <CtaButton ButtonText="Done" LinkTo="" />
        </div>
      </div> */}
    </Layout>
  );
};

export default MetricDetailLayout;
