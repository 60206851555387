import React from 'react';
import xclose from '../../assets/x-close.svg';
import DownArrowRed from '../../assets/DownArrowRed.svg';
import ListRedDisc from '../../assets/ListRedDisc.png';
import Tickbw from '../../assets/Tickbw.svg';
import ListGreenDisc from '../../assets/ListGreenDisc.png';
import DownArrowGreen from '../../assets/DownArrowGreen.svg';

const SleepApneaRiskDetailModal = ({ isOpen, onClose, heartRate }) => {
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-end justify-center backdrop-brightness-50 bg-black/30 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`w-full max-w-md rounded-t-[16px] bg-base_white shadow-md transition-transform duration-500 max-h-[75%] overflow-y-auto ${
            isOpen ? 'translate-y-0' : 'translate-y-full'
          }`}
        >
          {/* Modal content */}
          <div className="p-5">
            <div className="flex justify-between items-center mb-[2px]">
              <h2 className="text-[28px] font-inter font-normal leading-[33.6px] tracking-[-0.28px] text-light_bg_primary">
                Obstructive sleep apnea (OSA) score
              </h2>
              <button
                onClick={onClose}
                className="p-[6.66px] flex items-center justify-center bg-gray_100 rounded-[133.333px]"
              >
                <img src={xclose} alt="Close" />
              </button>
            </div>
            <p className="text-light_bg_secondary body-large font-inter mt-5">
              A screening tool used to determine your risk of developing sleep
              related breathing issues.
            </p>
            <h1 className="text-light_bg_primary heading-large mt-10 mb-2">
              What happens if you have OSA?
            </h1>
            <div className="bg-[#F9FAFB] p-4 rounded-[12px] flex-col flex gap-y-1">
              <div className="flex items-baseline">
                <img src={Tickbw} alt="Tick" />
                <p className="font-inter text-light_bg_primary opacity-85 ml-2">
                  Throat muscles might relax during sleep and block air passage
                </p>
              </div>
              <div className="flex">
                <img src={Tickbw} alt="Tick" />
                <p className="font-inter text-[16px] text-light_bg_primary opacity-85 ml-2">
                  Blocked breathing can cause snoring
                </p>
              </div>
            </div>

            <div className="flex-col flex gap-y-2">
              <h1 className="heading-large text-light_bg_primary mt-10 mb-2">
                What does it indicate?
              </h1>
              <div className="p-4 bg-[#FEF3F2] rounded-[16px]">
                <div className="flex">
                  <img
                    src={DownArrowRed}
                    alt="Down Arrow"
                    className="rotate-180"
                  />
                  <h1 className="heading-small ml-1 text-[#F04438]">
                    High score
                  </h1>
                </div>
                <div className="flex items-baseline mt-4">
                  <img
                    src={ListRedDisc}
                    alt="List Icon"
                    className="w-3 h-3 mr-2"
                  />
                  <p className="body-large text-light_bg_primary opacity-85">
                    High risk of sleep-related breathing and heart problems
                  </p>
                </div>
              </div>
              <div className="p-4 bg-[#ECFDF3] rounded-[16px]">
                <div className="flex">
                  <img src={DownArrowGreen} alt="Down arrow" />
                  <h1 className="heading-small ml-1 text-success_500">
                    Low score
                  </h1>
                </div>
                <div className="flex items-baseline mt-4">
                  <img
                    src={ListGreenDisc}
                    alt="List Icon"
                    className="w-3 h-3 mr-2"
                  />
                  <p className="body-large text-light_bg_primary opacity-85">
                    Low risk of sleep-related breathing and heart problems
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SleepApneaRiskDetailModal;
