import React from 'react';
import preOnboardSuccessLogo from '../assets/preOnboardSuccessLogo.svg';

const ComponentLoader = () => {
  const loaderStyle = {
    width: '80px',
    height: '80px',
    animation: 'heartBeat 1.5s infinite cubic-bezier(0.215, 0.61, 0.355, 1)',
    margin: 'auto',
  };

  const keyframesStyle = `
    @keyframes heartBeat {
      0% { transform: scale(0.95); }
      5% { transform: scale(1.1); }
      39% { transform: scale(0.85); }
      45% { transform: scale(1); }
      60% { transform: scale(0.95); }
      100% { transform: scale(0.9); }
    }
  `;

  return (
    <div className="flex items-center justify-center h-full w-full">
      <style>{keyframesStyle}</style>
      <img src={preOnboardSuccessLogo} alt="Loader" style={loaderStyle} />
    </div>
  );
};

export default ComponentLoader;
