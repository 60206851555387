// activityUtils.js

export const checkActivityAvailability = () => {
  try {
    const currentTimeIST = new Date();
    const currentHour = currentTimeIST.getHours();

    // Check if current time is within 7-10 PM window
    if (currentHour < 19 || currentHour >= 22) {
      return false;
    }

    // Get last activity submission time
    const lastActivityTime = localStorage.getItem('lastActivitySubmission');

    if (lastActivityTime) {
      const lastActivityTimeIST = new Date(lastActivityTime);
      const next7PM = new Date(lastActivityTimeIST);
      next7PM.setHours(19, 0, 0, 0);

      if (next7PM <= lastActivityTimeIST) {
        next7PM.setDate(next7PM.getDate() + 1);
      }

      return currentTimeIST >= next7PM;
    }

    return true; // If no previous activity, show during allowed hours
  } catch (error) {
    console.error('Error checking activity availability:', error);
    return true;
  }
};

export const markActivityComplete = () => {
  localStorage.setItem('lastActivitySubmission', new Date().toISOString());
};
