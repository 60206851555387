import React, { useState, useEffect, useRef } from 'react';
import facts from '../components/health_facts.json';
import { sendAmplitudeData } from '../utils/amplitude';

const Facts = () => {
  const [selectedFacts, setSelectedFacts] = useState([]);
  const scrollContainerRef = useRef(null);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  useEffect(() => {
    const getRandomFacts = () => {
      const shuffled = [...facts].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 5);
    };

    setSelectedFacts(getRandomFacts());
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer || !autoScrollEnabled) return;

    const autoScroll = () => {
      const maxScroll =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const currentScroll = scrollContainer.scrollLeft;

      // If we're at the end, go back to start
      if (currentScroll >= maxScroll) {
        scrollContainer.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      } else {
        // Otherwise, scroll to next item
        const nextScroll = Math.min(
          currentScroll + scrollContainer.clientWidth,
          maxScroll
        );
        scrollContainer.scrollTo({
          left: nextScroll,
          behavior: 'smooth',
        });
      }
    };

    const interval = setInterval(autoScroll, 3500);

    const handleUserScroll = () => {
      setAutoScrollEnabled(false);
      sendAmplitudeData('SCROLLED_FACTS');
      // Re-enable auto-scroll after 5 seconds of no user interaction
      setTimeout(() => setAutoScrollEnabled(true), 5000);
    };

    scrollContainer.addEventListener('scroll', handleUserScroll);

    return () => {
      clearInterval(interval);
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleUserScroll);
      }
    };
  }, [selectedFacts.length, autoScrollEnabled]);

  return (
    <div className="bg-gray_neutral_100 rounded-[16px] pb-3">
      <h2 className="text-gray_400 font-inter leading-6 font-[400] ml-5 py-2">
        FACTS FOR HEALTH
      </h2>
      <div
        ref={scrollContainerRef}
        className="flex overflow-x-auto space-x-3 ml-5 snap-x snap-mandatory"
      >
        {selectedFacts.map((fact, index) => (
          <div
            key={index}
            className="min-w-[336px] max-h-[250px] rounded-[12px] bg-base_white snap-center"
          >
            <p className="text-xl-regular pt-[35px] px-6 pb-[35px] text-gray_800">
              {fact.fact}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Facts;
